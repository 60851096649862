.profile_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  cursor: pointer;
  padding: 20px;
  height: 290px;

  .profile_info_image {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover !important;
    border-radius: 60px;
  }

  .profile_info_name {
    color: #142669;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 10px 0px 0px 0px;
  }

  .profile_info_program {
    color: #8993b4;
    font-size: 0.9rem;
    text-align: center;
    margin-top: 0px;
  }

  .profile_info_btn {
    border-radius: 10px;
  }
}
