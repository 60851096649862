.deal_card {
  position: relative;
  box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
  border-radius: 1rem !important;
  overflow: hidden;
  cursor: pointer;
  background: #fff;

  .card_image_container {
    position: relative;
    display: block;
    height: 14rem;

    .default-kit-image {
      height: 100%;
      z-index: 2;

      img {
        height: 100%;
        width: 100% !important;
        margin-bottom: 0.5rem;
        object-fit: cover !important;
        border-radius: 1rem;
      }
    }
  }

  .deal_info_container {
    padding: 1rem;
  }

  .vendor_info_container {
    display: flex;
    align-items: center;

    .vendor_logo {
      border-radius: 15px;
      width: 30px;
      height: 30px;
    }

    .vendor_name {
      font-family: Mont;
      font-size: 0.9rem;
      color: #142669;
      font-weight: 600;
      margin-left: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .deal_title {
    font-family: Mont;
    font-weight: 700;
    font-size: 1rem;
    color: #142669;
    margin: 10px 0px 0px 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.deal-locked {
  text-decoration: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 3;
  width: 100%;
  height: 100%; /* Fallback for browsers that don't support the below properties */
  height: -webkit-fill-available; /* Chrome, Safari, newer versions of Opera */
  height: -moz-available; /* Firefox */
  background: rgba(0, 0, 0, 0.7);

  img {
    display: flex;
  }

  p {
    display: block;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
}

.deal-locked-text {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}
