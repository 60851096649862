.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Book.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Regular.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Regular.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-SemiBold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Bold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/Mont/Mont-Heavy.ttf') format('truetype');
  font-weight: 900;
}
