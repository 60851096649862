.community__guidelines {
  .community__guidelines__container {
    padding: 3rem 5rem !important;
    max-width: 1000px;

    @media (max-width: 768px) {
      padding: 3rem !important;
    }

    .img {
      width: 300px;
      height: 300px;
      object-fit: contain;
    }

    h6 {
      font-weight: 700;
      color: #142669;
    }

    p,
    li,
    a {
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      color: #435187;
    }
  }
}
