.pawffice_hours_details {
  display: flex;
  justify-content: center;

  .pawffice_details_container {
    box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
    border-radius: 1rem !important;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    width: 800px;
    margin: 3rem 0rem;

    .deal_image_container {
      position: relative;
      display: block;
      height: 300px;
      margin-bottom: 20px;

      .kit-image {
        height: 100%;
        z-index: 2;

        img {
          height: 100%;
          width: 100% !important;
          margin-bottom: 0.5rem;
          object-fit: cover !important;
          border-radius: 0px;
        }
      }
    }

    .pawffice_tags {
      display: flex;
      align-items: center;
      list-style: none;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      margin-left: -0.25rem;
      margin-top: 1rem;

      li {
        color: #cc7191;
        background: #cc719115;
        margin-right: 0.75rem;
        margin-bottom: 0.5rem;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-family: 'Mont', Arial, Helvetica, sans-serif;
        font-size: 0.7rem;
        width: fit-content;
        padding: 0.5em 1em;
        border-radius: 0.75em;

        svg {
          font-size: 1.25rem;
          cursor: pointer;
        }
      }
    }
  }
}
