.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d0d4e1;
    transition: 0.4s;
    border-radius: 18px;

    &:before {
      position: absolute;
      content: '';
      height: 18px;
      width: 19px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  .text {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 5px;
    width: 21px;
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mont;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%; /* 16px */
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--toggle-bg, #cc7191);

    &:before {
      transform: translateX(25px);
    }
  }

  input:checked + .slider + .text {
    right: 24px;
  }
}
