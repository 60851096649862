.wrapper-404 {
  height: calc(100vh - 350px);
  min-height: 400px;
  font-weight: 100;
  font-size: 1.1rem;
}

.counter {
  font-weight: 600;
  color: #cc7190;
  font-size: 2rem;
}
