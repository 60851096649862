.buyer-offer {
  height: max-content;
  width: 100%;
  background: #f8f9fb;

  .image-container {
    margin-top: 80px;
  }

  .buyer-offer-container {
    padding: 60px 180px;
    background: #fff;
    width: auto;
    max-width: 850px !important;
    height: auto;
    margin: 80px auto;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      margin: 20px auto;
      padding: 20px 10px;
    }

    h4 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 114%;

      text-align: center;
      letter-spacing: 0.005em;
      color: #142669;

      margin-bottom: 20px;
      align-self: stretch;
    }

    p {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      text-align: center;
      font-feature-settings: 'liga' off;
      color: #142669;
      align-self: stretch;
    }

    .not-ready {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      text-align: center;
      font-feature-settings: 'liga' off;
      color: #142669;
      p {
        margin-left: 5px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 160%;
        text-align: center;
        font-feature-settings: 'liga' off;
        color: #cc7191;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .buyer-offer-img {
      margin: 20px 0;
      max-width: none;

      @media (max-width: 768px) {
        max-width: 100% !important;
        margin: 20px 10px;
      }
    }
  }
}
