.root {
  background: #fff;
  /* border-top: 1px solid #BDBDBD; */
  font-family: 'Mont', Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding-top: 0;
  margin-top: 2rem;
  position: relative;
}

.editorError,
.editor {
  min-height: 120px;
  border: 1px solid #bdbdbd;
  border-radius: 18px;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
}

.upload-button {
  color: #4f4f4f !important;
}

.editor figure video,
.editor figure img {
  height: 200px !important;
}

.editorError {
  border: 1px solid red;
}

.editor .blockQuote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.stylingContainer {
  display: flex;
  align-items: center;
}

.stylingContainer label {
  cursor: pointer;
}

.icon__camera {
  display: inline;
  vertical-align: middle;

  label {
    vertical-align: middle;
    display: inline-block;
    line-height: 0;
  }

  .custom__icon {
    margin-top: 1rem;
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.description {
  display: inline-block !important;
  width: fit-content;
  margin-right: 1rem !important;
}

.inputLabelNotCreate,
.inputLabel {
  font-size: 12px;
  font-family: 'Mont', Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

.inputLabelNotCreate {
  margin: 0.5rem 0 1rem 0;
  color: #4f4f4f;
}

.outerIcon {
  height: 16px;
  margin-bottom: 6px;
  /* margin-right: 8px; */
  margin-right: 0.75rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.buttonContainer button {
  width: fit-content;
  font-size: 0.9rem;
  padding: 0.75em 1.2em;
  background: #8bc2de20;
  color: #8bc2de;
  border-radius: 0.75em;
  font-family: 'Mont', Arial, Helvetica, sans-serif;
  border: none;
  outline: none;
}

/* .buttonContainer button:first-of-type {
  background: #e0e0e0;
  color: #828282;
  border: 2px solid #e0e0e0;
  margin-right: 1rem;
} */
.buttonContainer button:last-of-type {
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  .outerIcon {
    margin-right: 1.5rem;
  }
}

.inputLabelTag {
  font-size: 12px;
  font-family: 'Mont', Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

.tagInput {
  border: 1px solid #bdbdbd;
  border-radius: 18px;
  width: 100%;
  padding: 0.75em 0.5em;
  text-indent: 10px;
  // margin-top: 1rem;
  font-family: 'Mont', Arial, Helvetica, sans-serif;

  &:active {
    border-radius: 18px;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 5px #51cbee;
    border: 1px solid #51cbee;
  }
}

.tagTags {
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  padding: 0px;
  margin: 20px 0px 0px -0.25rem;

  li {
    color: #142669;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    background: #d7dae5;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    width: fit-content;
    padding: 0.5em 1em;
    border-radius: 0.75em;

    svg {
      font-size: 1.25rem;
      cursor: pointer;
      margin-top: -0.2rem;
    }
  }
}
