@import '../../../services/themes/colors';

.tt-button {
  padding: 0 25px;

  @media (max-width: 576px) {
    min-width: 100%;
  }
}

.btn-primary-orchid {
  background-color: $Orchid !important;
  border: 1.5px solid $Orchid !important;
  color: $White;
  &:hover {
    background-color: $Orchid-80 !important;
    border: 1.5px solid $Orchid-80 !important;
  }
  &:disabled {
    background-color: $Orchid-50 !important;
    border: 1.5px solid $Orchid-50 !important;
  }
}

.btn-primary-white {
  background-color: $White !important;
  border: 1.5px solid $Navy !important;
  color: $Navy !important;
  &:hover {
    background-color: $Navy !important;
    color: $White !important;
  }
  &:disabled {
    background-color: $White !important;
    border: 1.5px solid $Navy-50 !important;
    color: $Navy-50 !important;
  }
}

.btn-primary-navy {
  background-color: $Navy-10 !important;
  border: 1.5px solid $Navy-10 !important;
  color: $Navy !important;
  &:hover {
    color: $Navy-80 !important;
  }
  &:disabled {
    color: $Navy-50 !important;
  }
}

.btn-secondary-navy {
  background-color: $Navy !important;
  border: 1.5px solid $Navy !important;
  color: $White;
  &:hover {
    background-color: $Navy-80 !important;
    border: 1.5px solid $Navy-80 !important;
  }
  &:disabled {
    background-color: $Navy-20 !important;
    border: 1.5px solid $Navy-20 !important;
  }
}

.btn-neutral-navy {
  background-color: $Navy-10 !important;
  border: 1.5px solid $Navy-10 !important;
  color: $Navy !important;
  &:hover {
    background-color: $Navy !important;
    border: 1.5px solid $Navy !important;
    color: $Navy-10 !important;
  }
  &:disabled {
    background-color: $Navy-50 !important;
    border: 1.5px solid $Navy-50 !important;
    color: $Navy-10 !important;
  }
}

.btn-loading {
  border: none;
  outline: none;
  background-color: rgba($White, 0.2) !important;
  color: $Orchid !important;
  &:hover {
    background-color: rgba($White, 0.5) !important;
  }
  &:disabled {
    background-color: rgba($White, 0.2) !important;
  }
}
