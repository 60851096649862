.edit_breed_encyclopaedia {
  .ebe_rating-icon {
    padding: 0.5rem;
  }

  .ebe_gallery_container {
    width: 100%;

    .ebe_error_images {
      margin-top: 15px;
      font-family: 'Mont';
      font-style: normal;
      font-size: 20px;
      color: #cc7191;
      width: 100%;
      text-align: center;
    }

    .fileContainer {
      box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 5%);
    }

    .ebe_upload_container {
      background: #ddd;
      padding: 20px;
      border-radius: 10px;
      margin-top: 10px;
    }

    .ebe_gallery_upload {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      padding-top: 30px;

      .ebe_upload_thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        padding: 0px;
        border: 1px solid #333;
        border-radius: 10px;
        background: #e4e4e4;
        width: 170px !important;
        height: 170px !important;

        img {
          object-fit: contain;
          width: 100%;
          height: -webkit-fill-available;
        }

        .delete-icon {
          position: absolute;
          color: red;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
