.desktop-container {
  @media (max-width: 768px) {
    display: none;
  }
}

.referral-offer {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  background: #e8f3f8;
}

.referral-offer-text {
  max-width: 435px;
  color: #142669;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Mont;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.referral-offer-subtext {
  max-width: 435px;
  margin-top: 10px;
  color: #142669;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
}

.referral-offer-img {
  max-width: 100%;
}

.referral-banner {
  background-color: #c4e1f0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/images/breederProfile/referralBannerDesktop.jpeg');
  width: 100vw;
  height: 114px;
  cursor: pointer;

  @media (max-width: 1400px) {
    background-image: url('../../assets/images/breederProfile/referralBannerTablet.jpeg');
  }

  @media (max-width: 768px) {
    height: 182px;
    background-image: url('../../assets/images/breederProfile/referralBannerMobile.jpeg');
  }
}

.banner-terms {
  background-color: #14256a;
  color: #d0d4e1;
  width: 100vw;
  display: flex;
  padding: 10px 10px 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  a {
    margin: 0;
    color: #d0d4e1;
    text-align: center;
    font-family: Mont;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-decoration-line: underline;
  }
}

.referral-terms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  a {
    margin: 0;
    color: #cc7191;
    text-align: center;
    font-family: Mont;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-decoration-line: underline;
  }
}

.public_button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 30px auto 0 auto;
  width: 100%;
  max-width: 1200px;
  text-decoration: none;

  button {
    display: flex;
    align-items: center;
    border-radius: 10px;
    img {
      margin-bottom: 2px;
    }
  }

  @media (max-width: 1200px) {
    margin: 20px 10px;
    max-width: -webkit-fill-available;
  }
}

.profileInfo__container {
  max-width: 1200px;
  background: white;
  margin: 60px 0;
  padding: 30px;
  border-radius: 12px;

  &.loaded {
    margin: 10px 0 20px 0;
  }

  @media (max-width: 1200px) {
    max-width: -webkit-fill-available;
  }

  .MuiPaper-root {
    max-width: -webkit-fill-available;
  }

  .profileInfo__tabsContainer {
    @media (max-width: 1200px) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #d0d4e1;
    }
  }

  .profileInfo__tabs {
    position: sticky;
    z-index: 10;
    top: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    min-width: 300px;

    background: white;
    border-radius: 14px;

    .profileInfo__tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      gap: 5px;
      min-width: 300px;
      height: 45px;

      text-decoration: none;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;

      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;
      color: #142669;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-bottom: 3px;
      }

      p {
        margin: 0;
        text-decoration: none;
        color: #142669;
      }

      &.active {
        background: #f5e3e9;
        border-radius: 12px;
      }
    }
  }

  .profileInfo__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    .upload_container {
      width: 100%;
      border-top: 1px solid #d0d4e1;
      padding-top: 30px;

      .error_images {
        margin-top: 20px;
        font-family: 'Mont';
        font-style: normal;
        font-size: 20px;
        color: #cc7191;
        width: 100%;
        text-align: center;
      }

      .fileContainer {
        box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 5%);
      }

      .gallery_upload {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        padding-top: 30px;

        .upload_thumbnail {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0px;
          border-radius: 10px;
          background: #e4e4e4;
          width: 170px !important;
          height: 170px !important;

          img {
            object-fit: contain;
            width: 100%;
            height: -webkit-fill-available;
          }
        }
      }
    }

    .gallery_icons_info {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #142669;
      // line-height: 250%;
    }

    .gallery {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .carousel {
        max-width: 100%;
        height: auto;
        margin: auto;

        .thumbs-wrapper {
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-left: 0 !important;
          margin-right: 0 !important;

          ul {
            transform: none !important;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            li {
              position: relative;

              display: flex;
              justify-content: center;
              align-items: center;

              padding: 0px;
              border-radius: 10px;
              background: #e4e4e4;
              width: 170px !important;
              height: 170px !important;

              &.selected {
                border: 1px solid #142669 !important;
              }

              &:hover {
                border: 1px solid #142669 !important;
              }

              img {
                object-fit: contain;
              }
            }
          }
        }
      }

      .gallery_thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;

        // object-fit: contain;
      }

      .gallery_img {
        width: 100%;
        height: 400px;
        max-width: 100%;
        max-height: 400px;

        img {
          width: 100%;
          height: -webkit-fill-available;
          object-fit: contain;
        }
      }
    }

    .change_pass_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 100%;
      border-top: 1px solid #d0d4e1;
      padding-top: 30px;
    }

    .match_table {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;

      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;

      color: #142669;

      &.bold {
        font-weight: 700;
      }
    }

    .my_acc_form {
      width: 100%;
      padding-bottom: 30px;
    }

    .my_program_subtitle {
      color: #142669;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      font-feature-settings: 'liga' off;
    }

    h4 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 114%;
      letter-spacing: 0.005em;
      color: #142669;
    }

    h6 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      font-feature-settings: 'liga' off;
      color: #142669;
    }

    .save_btn {
      border-radius: 10px;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
    }

    .save_btn_sfc_dl {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px;
      box-shadow: 3px 3px 3px #969696;
      z-index: 100;
      svg {
        background: transparent !important;
      }
    }

    .delete_btn_sfc_dl {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      min-width: 35px !important;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .bookmark_btn_sfc_dl {
      position: absolute;
      top: 0;
      right: 38px;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      min-width: 35px !important;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .bookmark_btn_info {
      position: relative;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      margin-bottom: 5px;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .scam-free-certified {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px;
      gap: 10px;
      width: 740px;
      max-width: 740px;

      border: 1px solid #d0d4e1;
      border-radius: 20px;

      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .scam-free-certified__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 768px) {
          align-items: center;
        }
      }

      h4 {
        margin: 0;
        height: 36px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 114%;

        text-align: center;
        letter-spacing: 0.005em;

        color: #142669;
      }

      p {
        margin: 0;
        height: 26px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 100;
        font-size: 16px;
        line-height: 160%;

        text-align: center;
        font-feature-settings: 'liga' off;

        color: #142669;

        span {
          font-weight: 600;
          color: #cc7191;
        }
      }
    }

    .badges-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.3rem;
      margin-bottom: 20px;
      width: 100%;

      .dl_what_certified_means {
        width: 352px;
        height: 352px;
      }

      &.no_border {
        border-bottom: none;
      }

      &.no_margin {
        margin-bottom: 0px !important;
      }

      .download_materials_image {
        position: relative;
      }
    }

    .certification_badge {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 10px 0;
      gap: 10px;

      .download_materials_image {
        position: relative;
      }
    }

    .sfc-subtitle {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      font-feature-settings: 'liga' off;
      color: #142669;
    }
  }
}

.litter_modal {
  z-index: 20002;

  .modal-content {
    z-index: 20002;
    min-width: 600px;

    input {
      background: transparent;
      border: 1px solid #bdbdbd;
      border-radius: 1.25em;
      font-family: Mont, Arial, Helvetica, sans-serif;
      font-size: 0.9rem;
      margin-bottom: 1rem;
      padding: 1em 0.75em;
    }

    textarea {
      background: transparent;
      border: 1px solid #bdbdbd;
      border-radius: 1.25em;
      font-family: Mont, Arial, Helvetica, sans-serif;
      font-size: 0.9rem;
      margin-bottom: 1rem;
      padding: 1em 0.75em;
    }

    @media (max-width: 768px) {
      min-width: 200px !important;
      width: 100%;
    }
  }

  h4 {
    margin: 30px 0 10px 0;
    height: 36px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 114%;

    text-align: center;
    letter-spacing: 0.005em;

    color: #142669;
  }
  p {
    width: 100%;
    margin: 0;
    height: 26px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 160%;

    text-align: center;
    font-feature-settings: 'liga' off;

    color: #142669;
  }

  .my_litter_form {
    padding: 20px 30px 30px 30px;

    @media (max-width: 768px) {
      padding: 20px 15px 15px 15px !important;
      margin-top: 30px;
    }

    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        width: 100%;

        input {
          height: 48px;
          width: 100%;
        }
      }
    }

    .signupOnboarding__listInput {
      display: flex;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
    }

    .save_btn_litter {
      border-radius: 10px;
    }

    label {
      font-size: 0.85rem;
      font-weight: 600;
      color: #4f4f4f;
      font-family: 'Mont', Arial, Helvetica, sans-serif;

      &.sublabel {
        font-weight: 200;
        font-style: italic;
        white-space: pre-wrap;
      }
      &.signupOnboarding__radio {
        margin-right: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #142669;

        input {
          margin: 0 5px;
        }
      }

      i {
        color: #142669;
      }
    }
  }
}

.tooltip_trigger_account {
  border-radius: 10px;
  border: none;
  margin-bottom: 0.5rem;

  font-family: 'Mont';
  font-style: normal;
  font-weight: 600;
  background: transparent;

  &--leads {
    @extend .tooltip_trigger_account;
    margin-bottom: 0.2rem !important;
    margin-left: -6px;
  }
}

.tooltip_account {
  width: 400px;
  z-index: 999;
  background: #e7e7e7;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: 'liga' off;
  color: #142669;
  box-shadow: 0px 0px 20px 0px #d4d4d4;

  &.dl_tab {
    width: 300px;
  }

  &--leads {
    @extend .tooltip_account;
    background: #fff;

    @media (max-width: 430px) {
      width: 250px;
    }
  }
}

.img-locked-buyer {
  margin-bottom: 3px;
}

.unlock-matches {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Navy-Navy---10, #e7e9f0);

  @media (max-width: 768px) {
    padding: 20px;
  }

  h6 {
    margin: 0;
    color: #142669;
    text-align: center;
    font-feature-settings: 'liga' off;
    font-size: 16px;
    font-family: Mont;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  p {
    margin: 0;
    align-self: stretch;
    color: var(--Dark-Neutral, #142669);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Mont;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }

  a {
    color: #cc7191 !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mont;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    text-decoration-line: underline !important;
    cursor: pointer;
  }
}

.no-leads-img {
  padding: 50px 15px;
}

.mobile-container {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;

  &.no-padding {
    padding: 0px;
  }

  @media (min-width: 769px) {
    display: none;
  }

  .mobile-tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 100%;

    background: white;
    border-radius: 14px;

    .mobile-tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      gap: 5px;
      width: 100%;
      height: 45px;

      text-decoration: none;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      font-feature-settings: 'liga' off;
      color: #142669;

      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-bottom: 3px;
      }

      a {
        text-decoration: none;
        color: #142669;
      }

      &.active {
        background: #f5e3e9;
        border-radius: 12px;
      }
    }
  }

  .m-table-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    height: auto;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #e7e9f0;
    background: #fff;

    &.align-end {
      align-items: flex-end;
    }

    p {
      margin: 0;
      color: #142669;
      font-size: 16px;
      font-family: Mont;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;

      &.bold-match {
        font-weight: 700;
      }

      &.new-match {
        color: #cc7191 !important;
        font-size: 11px !important;
        font-family: Mont !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 160% !important;
        letter-spacing: 1.32px !important;
      }
    }
  }

  .m-table-card__left {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }

  .profileInfo__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    .upload_container {
      width: 100%;
      border-top: 1px solid #d0d4e1;
      padding-top: 30px;

      .error_images {
        margin-top: 20px;
        font-family: 'Mont';
        font-style: normal;
        font-size: 20px;
        color: #cc7191;
        width: 100%;
        text-align: center;
      }

      .fileContainer {
        box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 5%);
      }

      .gallery_upload {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        padding-top: 30px;

        .upload_thumbnail {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0px;
          border-radius: 10px;
          background: #e4e4e4;
          width: 170px !important;
          height: 170px !important;

          img {
            object-fit: contain;
            width: 100%;
            height: -webkit-fill-available;
          }
        }
      }
    }

    .gallery_icons_info {
      margin-top: 20px;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #142669;
      // line-height: 250%;
    }

    .gallery {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .carousel {
        max-width: 100%;
        height: auto;
        margin: auto;

        .thumbs-wrapper {
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-left: 0 !important;
          margin-right: 0 !important;

          ul {
            transform: none !important;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            li {
              position: relative;

              display: flex;
              justify-content: center;
              align-items: center;

              padding: 0px;
              border-radius: 10px;
              background: #e4e4e4;
              width: 170px !important;
              height: 170px !important;

              &.selected {
                border: 1px solid #142669 !important;
              }

              &:hover {
                border: 1px solid #142669 !important;
              }

              img {
                object-fit: contain;
              }
            }
          }
        }
      }

      .gallery_thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;

        // object-fit: contain;
      }

      .gallery_img {
        width: 100%;
        height: 400px;
        max-width: 100%;
        max-height: 400px;

        img {
          width: 100%;
          height: -webkit-fill-available;
          object-fit: contain;
        }
      }
    }

    .change_pass_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 100%;
      border-top: 1px solid #d0d4e1;
      padding-top: 30px;
    }

    .match_table {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;

      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;

      color: #142669;

      &.bold {
        font-weight: 700;
      }
    }

    .my_acc_form {
      width: 100%;
      padding-bottom: 30px;
    }

    .my_program_subtitle {
      color: #142669;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      font-feature-settings: 'liga' off;
    }

    h4 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 114%;
      letter-spacing: 0.005em;
      color: #142669;

      @media (max-width: 768px) {
        font-size: 20px;

        &.leads {
          margin: 0 0 -35px 0;
        }

        &.in-review {
          margin: 0;
        }
      }
    }

    h6 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      font-feature-settings: 'liga' off;
      color: #142669;
    }

    .save_btn {
      border-radius: 10px;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
    }

    .save_btn_sfc_dl {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px;
      box-shadow: 3px 3px 3px #969696;
      z-index: 100;
      svg {
        background: transparent !important;
      }
    }

    .delete_btn_sfc_dl {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      min-width: 35px !important;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .bookmark_btn_sfc_dl {
      position: absolute;
      top: 0;
      right: 38px;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      min-width: 35px !important;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .bookmark_btn_info {
      position: relative;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      margin-bottom: 5px;
      min-width: 30px !important;
      max-width: 30px !important;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .scam-free-certified {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      gap: 10px;
      width: 740px;
      max-width: 740px;

      border: 1px solid #d0d4e1;
      border-radius: 20px;

      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .scam-free-certified__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 768px) {
          align-items: center;
        }
      }

      h4 {
        margin: 0;
        height: 36px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 114%;

        text-align: center;
        letter-spacing: 0.005em;

        color: #142669;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      p {
        margin: 0;
        height: 26px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 100;
        font-size: 16px;
        line-height: 160%;

        text-align: center;
        font-feature-settings: 'liga' off;

        color: #142669;

        @media (max-width: 768px) {
          height: auto;
        }

        span {
          font-weight: 600;
          color: #cc7191;
        }
      }
    }

    .badges-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.3rem;
      margin-bottom: 20px;
      width: 100%;

      .dl_what_certified_means {
        width: 352px;
        height: 352px;
      }

      &.no_border {
        border-bottom: none;
      }

      &.no_margin {
        margin-bottom: 0px !important;
      }

      .download_materials_image {
        position: relative;
      }
    }

    .certification_badge {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 10px 0;
      gap: 10px;

      .download_materials_image {
        position: relative;
      }
    }

    .sfc-subtitle {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      font-feature-settings: 'liga' off;
      color: #142669;
    }
  }
}

.new-match-table {
  position: absolute;
  top: -3px;
  color: #cc7191;
  font-size: 11px;
  font-family: Mont;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 1.32px;
  margin: 0;
}

.higher-table-row {
  padding: 15px 0;
}

.mobile-litter-modal-buttons {
  @media (max-width: 768px) {
    margin-bottom: 11rem;
  }
}

.add-litter {
  &__bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 24px;
  }
}

.cards-container {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.leads-card {
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--Navy-Navy---10, #e7e9f0);
  border-radius: 10px;
  position: relative;

  &--disabled {
    pointer-events: none;
  }

  &__blur-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    display: none;
    border-radius: 10px;

    &--active {
      opacity: 0.95;
      display: block;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  p {
    margin: 0;
    font-feature-settings: 'liga' off;
    font-family: Mont;
  }

  &__score-description {
    margin: 0;
    color: var(--Navy-Navy---100, #142669);
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 160%;
  }

  &__score {
    margin: 15px 15px 10px 15px;
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;

    p {
      margin: 0;
      color: var(--Navy-Navy---100, #142669);
      text-align: center;
      font-feature-settings: 'liga' off;
      font-family: Mont;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 22.4px */
    }

    img {
      cursor: pointer;
    }

    &--great {
      border-radius: 8px;
      border: 1px solid #41c18d;
      background: #ecf9f4;
    }

    &--good {
      border-radius: 8px;
      border: 1px solid #fbbc32;
      background: #fffcf3;
    }

    &--ok {
      border-radius: 8px;
      border: 1px solid #f89046;
      background: #fef7f3;
    }

    .great {
      color: #41c18d;
    }
    .good {
      color: #fbbc32;
    }
    .ok {
      color: #f89046;
    }
  }

  &__info-banner {
    min-height: 23px;
    display: flex;
    padding: 8px 16px 0 16px;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    align-self: stretch;

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: start;
      gap: 2px;
    }
  }

  &__body {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    flex-grow: 1;

    background: #fff;
    border-radius: 10px;
  }

  &__heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 40px;
  }

  &__heading-left {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    :last-child {
      border: none;
    }
  }

  &__field {
    width: 100%;
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Navy-Navy---10, #e7e9f0);
  }

  &__title {
    color: var(--Navy-Navy---100, #142669);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  &__verification-status {
    color: var(--Orchid-Orchid---100, #cc7191);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mont;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;

    &--verified {
      font-weight: 700;
      color: #81b29a;
    }
  }

  &__description {
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
    color: var(--Navy-Navy---100, #142669);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    overflow-wrap: anywhere;
  }

  &__subtitle {
    color: var(--Navy-Navy---80, #435187);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;

    &--navy-50 {
      color: var(--Navy-Navy---50, #8993b4);
    }
  }

  &__warning {
    $this: &;
    color: #ed5757;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;

    &--large {
      @extend #{$this};
      font-size: 14px;
    }
  }

  &__info-new-text {
    color: var(--Navy-Navy---100, #142669);
    font-feature-settings: 'liga' off;

    font-family: Mont;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 19.2px */
  }

  &__info-archive {
    align-self: flex-end;
    color: var(--Orchid-Orchid---100, #cc7191);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Mont;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 19.2px */
    text-decoration-line: underline;
    cursor: pointer;
  }

  &__img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &__icon {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    align-self: flex-end;
    cursor: pointer;
  }
}

.leads-subtabs {
  width: calc(100% - 16px);
  margin: -50px 0 14px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  &--in-review {
    margin: -20px 0 14px 0 !important;
  }

  &--basic-with-leads {
    margin: 10px 0 14px 0 !important;
  }

  @media (max-width: 768px) {
    margin: 0 0 5px 0;
  }
}

.leads-subtab {
  margin: 0;
  color: var(--Navy-Navy---80, #435187);
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */

  cursor: pointer;

  &--active {
    border-bottom: 1px solid #cc7191;
  }

  &--disabled {
    color: #d0d4e1;
    pointer-events: none;
  }
}

.modal-archived {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  pointer-events: all;

  width: 100%;
  height: 50%;
  padding: 4px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &--active {
    display: flex;
  }

  &__title {
    color: var(--Navy-Navy---100, #142669);
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }

  &__subtitle {
    color: var(--Navy-Navy---100, #142669);
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
  }
}
