header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100% !important;
  background: white;
  padding: 0.8rem 2.5rem;
  z-index: 300 !important;
  border-bottom: 1px solid #e7e9f0;

  @media (max-width: 596px) {
    padding: 0.5rem 1rem;
  }

  .dev-panel {
    width: 'auto';
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 1px solid #9ea5c1;

    @media (max-width: 596px) {
      margin-left: 0rem;
      padding-left: 0rem;
      border: none;
      width: 110px;
    }
  }

  .login-join-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 596px) {
      display: none;
    }

    .text {
      color: var(--Navy-Navy---100, #142669);
      font-feature-settings: 'liga' off;
      font-family: Mont;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }
  }

  // hamburger icon starts
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 22px;
    width: 22px;
  }

  .hamburger .bar {
    height: 3px;
    width: 100%;
    background-color: #142669;
    transition: all 100ms ease-in-out;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  .x:nth-of-type(1) {
    transition: all 100ms ease-in-out;
    transform: rotate(45deg);
    transform-origin: top left;
    width: 28px;
  }

  .x:nth-of-type(2) {
    transition: all 100ms ease-in-out;
    transform-origin: center;
    width: 0;
  }

  .x:nth-of-type(3) {
    transition: all 100ms ease-in-out;
    transform: rotate(-45deg);
    transform-origin: bottom left;
    width: 28px;
  }
  // hamburger icon ends
}

.overlay {
  width: 100%;
  max-height: 100%;
  position: absolute;
  background-color: #0003;
  inset: 0;
  z-index: 200 !important;
  visibility: visible;
  opacity: 1;
  transition: all 0.6s;

  &--hide {
    visibility: hidden;
    opacity: 0;
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 380px;
  max-height: 100vh;
  padding: 84px 16px 16px 16px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200 !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: all 0.3s ease-out;

  @media (max-width: 596px) {
    width: 80%;
    padding: 72px 16px 16px 16px;
  }

  @media (max-width: 300px) {
    width: 260px;
    padding: 72px 16px 16px 16px;
  }

  &--open {
    transform: translateX(0%);
  }

  &--close {
    transform: translateX(100%);
    box-shadow: none;
  }

  %text {
    font-feature-settings: 'liga' off;
    font-family: Mont;
  }

  &__profile-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }

    &__name {
      @extend %text;
      color: var(--Navy-Navy---100, #142669);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }

    &__program-name {
      @extend %text;
      color: var(--Navy-Navy---50, #8993b4);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 25.6px */
    }
  }

  &__button {
    background: var(--Navy-Navy---5, #f3f4f8) !important;
    color: var(--Navy-Navy---100, #142669) !important;
    font-weight: 700;
    border: none;
  }

  &__header {
    @extend %text;
    color: var(--Navy-Navy---50, #8993b4);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
    letter-spacing: 3.6px;
    text-transform: uppercase;
  }

  &__link {
    @extend %text;
    color: var(--Navy-Navy---100, #142669);
    background-color: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 25.6px */
    text-decoration: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 12px;

    &:hover {
      color: var(--Navy-Navy---100, #142669);
      background-color: #cc719120;
    }

    &--selected {
      background-color: #cc719140;
    }
  }
}
