.admin_profileInfo__container {
  max-width: 1200px;
  background: white;
  margin: 20px auto;
  padding: 30px;
  border-radius: 12px;

  &.loaded {
    margin: 10px auto 20px auto;
  }

  @media (max-width: 1200px) {
    max-width: -webkit-fill-available;

    &.loaded {
      margin: 10px;
    }
  }

  .MuiPaper-root {
    max-width: -webkit-fill-available;
  }

  .imp_factors_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    font-feature-settings: 'liga' off;

    color: #142669;
  }

  .imp_factors_text_orchid {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'liga' off;

    color: #8bc2de;

    &.big {
      margin-top: 4rem;
      font-size: 18px;
      color: #cc7191;
    }
  }

  .table_breeder_image {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-right: 10px;
    object-fit: cover;
  }

  .profileInfo__tabsContainer {
    @media (max-width: 1200px) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #d0d4e1;
    }
  }

  .admin_profileInfo__tabs {
    position: sticky;
    z-index: 10;
    top: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    min-width: 300px;

    background: white;
    border-radius: 14px;

    .profileInfo__tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      gap: 5px;
      min-width: 300px;
      height: 45px;

      text-decoration: none;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      /* identical to box height, or 26px */

      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;

      /* Navy/Navy - 100 */

      color: #142669;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-bottom: 3px;
      }

      a {
        text-decoration: none;
        color: #142669;
      }

      &.active {
        background: #f5e3e9;
        border-radius: 12px;
      }
    }
  }

  .profileInfo__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    .upload_container {
      width: 100%;
      border-top: 1px solid #d0d4e1;
      padding-top: 30px;

      .error_images {
        margin-top: 20px;
        font-family: 'Mont';
        font-style: normal;
        font-size: 20px;
        color: #cc7191;
        width: 100%;
        text-align: center;
      }

      .fileContainer {
        box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 5%);
      }

      .gallery_upload {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        padding-top: 30px;

        .upload_thumbnail {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0px;
          border-radius: 10px;
          background: #e4e4e4;
          width: 170px !important;
          height: 170px !important;

          img {
            object-fit: contain;
            width: 100%;
            height: -webkit-fill-available;
          }
        }
      }
    }

    .gallery_icons_info {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #142669;
      // line-height: 250%;
    }

    .gallery {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .carousel {
        max-width: 100%;
        height: auto;
        margin: auto;

        .thumbs-wrapper {
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-left: 0 !important;
          margin-right: 0 !important;

          ul {
            transform: none !important;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            li {
              position: relative;

              display: flex;
              justify-content: center;
              align-items: center;

              padding: 0px;
              border-radius: 10px;
              background: #e4e4e4;
              width: 170px !important;
              height: 170px !important;

              &.selected {
                border: 1px solid #142669 !important;
              }

              &:hover {
                border: 1px solid #142669 !important;
              }

              img {
                object-fit: contain;
              }
            }
          }
        }
      }

      .gallery_thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;

        // object-fit: contain;
      }

      .gallery_img {
        width: 100%;
        height: 400px;
        max-width: 100%;
        max-height: 400px;

        img {
          width: 100%;
          height: -webkit-fill-available;
          object-fit: contain;
        }
      }
    }

    .change_pass_container_buyer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 100%;
    }

    .match_table {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;

      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;

      color: #142669;

      &.bold {
        font-weight: 700;
      }
    }

    .my_acc_form {
      width: 100%;
      padding-bottom: 30px;
    }

    .my_program_subtitle {
      color: #142669;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      font-feature-settings: 'liga' off;
    }

    h4 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 114%;
      letter-spacing: 0.005em;
      color: #142669;
    }

    h6 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      font-feature-settings: 'liga' off;
      color: #142669;
    }

    .save_btn {
      border-radius: 10px;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
    }

    .save_btn_sfc_dl {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px;
      box-shadow: 3px 3px 3px #969696;
      z-index: 100;
      svg {
        background: transparent !important;
      }
    }

    .delete_btn_sfc_dl {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .bookmark_btn_sfc_dl {
      position: absolute;
      top: 0;
      right: 38px;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .bookmark_btn_info {
      position: relative;
      border-radius: 5px 5px 5px 5px;
      text-align: center;
      line-height: 20px;
      padding: 0px !important;
      box-shadow: -3px 3px 3px #969696;
      z-index: 100;
      margin-bottom: 5px;

      svg {
        background: transparent !important;
        height: 20px;
      }
    }

    .scam-free-certified {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      gap: 10px;
      width: 740px;
      max-width: 740px;

      border: 1px solid #d0d4e1;
      border-radius: 20px;

      h4 {
        margin: 0;
        height: 36px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 114%;

        text-align: center;
        letter-spacing: 0.005em;

        color: #142669;
      }

      p {
        margin: 0;
        height: 26px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 100;
        font-size: 16px;
        line-height: 160%;

        text-align: center;
        font-feature-settings: 'liga' off;

        color: #142669;

        span {
          font-weight: 600;
          color: #cc7191;
        }
      }
    }

    .badges-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.3rem;
      margin-bottom: 20px;
      width: 100%;

      .dl_what_certified_means {
        width: 352px;
        height: 352px;
      }

      &.no_border {
        border-bottom: none;
      }

      &.no_margin {
        margin-bottom: 0px !important;
      }

      .download_materials_image {
        position: relative;
      }
    }

    .certification_badge {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 10px 0;
      gap: 10px;

      .download_materials_image {
        position: relative;
      }
    }

    .sfc-subtitle {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      font-feature-settings: 'liga' off;
      color: #142669;
    }
  }
}

.litter_modal {
  .modal-content {
    min-width: 600px;

    @media (max-width: 768px) {
      min-width: 400px;
    }
  }

  h4 {
    margin: 30px 0 10px 0;
    height: 36px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 114%;

    text-align: center;
    letter-spacing: 0.005em;

    color: #142669;
  }
  p {
    margin: 0;
    height: 26px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 160%;

    text-align: center;
    font-feature-settings: 'liga' off;

    color: #142669;
  }

  .my_litter_form {
    padding: 20px 30px 30px 30px;

    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        width: 100%;

        input {
          height: 48px;
          width: 100%;
        }
      }
    }

    .signupOnboarding__listInput {
      display: flex;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
    }

    .save_btn_litter {
      border-radius: 10px;
    }

    label {
      font-size: 0.85rem;
      font-weight: 600;
      color: #4f4f4f;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      &.sublabel {
        font-weight: 200;
        font-style: italic;
        white-space: pre-wrap;
      }
      &.signupOnboarding__radio {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #142669;
      }

      i {
        color: #142669;
      }
    }
  }
}

.tooltip_trigger_account {
  border-radius: 10px;
  border: none;
  margin-bottom: 0.5rem;

  font-family: 'Mont';
  font-style: normal;
  font-weight: 600;
  background: transparent;
}

.tooltip_account {
  width: 400px;
  z-index: 999;
  background: #e7e7e7;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: 'liga' off;
  color: #142669;
  box-shadow: 0px 0px 20px 0px #d4d4d4;

  &.dl_tab {
    width: 300px;
  }
}

.did_you_know_profile {
  margin: 0 0 1rem 0;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: 'liga' off;
  color: #142669;
}
