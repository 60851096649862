.editUserProfile {
  padding: 1rem 0rem;
  background: #f3f4f8;
  overflow: scroll;

  .editUserProfile__cotainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    h6 {
      font-weight: 700;
    }

    select {
      font-family: 'Mont', Arial, Helvetica, sans-serif;

      option {
        padding: 0.5em 0.25em;
        cursor: pointer;

        &:checked {
          background: #cc7191;
          color: #fff;
        }
      }
    }

    .editUserProfile__alertError {
      position: sticky;
      top: 1rem;
      margin-top: -3rem;
      margin-bottom: 3rem;
      width: 80%;
      z-index: 10;
      display: flex;
      align-items: center;

      color: #fff;
      background-color: #e63946;

      svg {
        font-size: 1.25rem;
      }

      p {
        margin-bottom: 0;
        margin-left: 0.75rem;
      }

      button {
        position: absolute;
        right: 1rem;
        color: #fff;
        background: transparent;
        border: none;
      }
    }

    .editUserProfile__alertSuccess {
      position: sticky;
      top: 1rem;
      margin-top: -3rem;
      margin-bottom: 3rem;
      width: 80%;
      z-index: 10;
      display: flex;
      align-items: center;

      svg {
        font-size: 1.25rem;
      }

      p {
        margin-bottom: 0;
        margin-left: 0.75rem;
      }

      button {
        position: absolute;
        right: 1rem;
        color: #fff;
        background: transparent;
        border: none;
      }
    }

    .editUserProfile__tags {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      margin-left: -0.25rem;
      flex-wrap: wrap;

      &.tags {
        li {
          color: #cc7191;
          background: #cc719115;
        }
      }

      li {
        color: #8bc2de;
        margin-right: 0.25rem;
        margin-bottom: 0.5rem;
        width: fit-content !important;
        font-weight: 700;
        background: #8bc2de15;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-family: 'Mont', Arial, Helvetica, sans-serif;
        font-size: 0.7rem !important;
        padding: 0.5em 1em;
        border-radius: 0.75em;

        svg {
          font-size: 1.25rem;
          cursor: pointer;
        }
      }
    }

    .editUserProfile__alreadyHaveAcc {
      display: block;
      margin-top: 0.75rem;
      font-weight: 600;
      font-family: 'Mont', Arial, Helvetica, sans-serif;

      a {
        font-weight: 700;
        color: #cc7191;
        text-decoration: none;
      }
    }

    .editUserProfile__btnContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;

      button {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
      }
    }

    .editUserProfile__mainImg {
      width: 100%;
      max-width: 25rem;
      margin-bottom: 2rem;
    }

    .editUserProfile__subtitle {
      text-align: center;
      font-weight: 600;
      display: block;
      margin: 0;
      font-size: 1rem;
      line-height: 1.5;
      max-width: 38rem;
      color: #142669;
      margin-top: 1rem;
    }

    .editUserProfile__title {
      font-weight: 700;
      color: #142669;
      text-align: center;
      max-width: 50rem;
      margin: 0;
      font-size: 1.75rem;

      @media (min-width: 768px) {
        font-size: 2rem;
      }

      @media (min-width: 992px) {
        font-size: 2.5rem;
      }
    }

    .editUserProfile__head {
      margin-bottom: 3.5rem;
      display: flex;
      align-items: center;
      max-width: 50rem;
      flex-direction: column;
      width: 100%;
    }

    .editUserProfile__content {
      background: #fff;
      width: 100%;
      max-width: 70rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5em 2em;
      border-radius: 2em;

      .editUserProfile__socialMedia {
        h4 {
          margin: 0;
          padding: 0;
        }

        input {
          margin: 0;
          max-width: 20rem;
          margin-left: auto;
        }
      }

      .editUserProfile__stepperCotainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: -2rem;

        .editUserProfile__stepNumber {
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 1rem;
          font-family: 'Mont', Arial, Helvetica, sans-serif;
        }

        .editUserProfile__steps {
          display: flex;
          align-items: center;

          .editUserProfile__step {
            // cursor: pointer;
            width: 2.5rem;
            height: 0.35rem;
            background: #14266910;
            border-radius: 3em;
            margin-right: 0.5rem;

            &.active {
              background: #cc7191;
            }

            @media (max-width: 768px) {
              width: 1.5rem;
            }
          }
        }
      }

      .editUserProfile__rowRadioList {
        label {
          margin-top: 1rem;
        }
      }

      textarea,
      input {
        font-family: 'Mont', Arial, Helvetica, sans-serif;
        padding: 1em 0.75em;
        background: transparent;
        border-radius: 1.25em;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        border: 1px solid #bdbdbd;

        &::placeholder {
          color: #bdbdbd;
        }

        &[type='number'] {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          -moz-appearance: textfield;
        }
      }

      textarea {
        height: 9rem;
        resize: none;

        &.small {
          height: 5rem;
        }
      }

      label {
        font-size: 0.85rem;
        font-weight: 600;
        color: #4f4f4f;
        font-family: 'Mont', Arial, Helvetica, sans-serif;
        &.sublabel {
          font-weight: 200;
          font-style: italic;
          white-space: pre-wrap;
        }
        &.checkbox,
        &.editUserProfile__radio {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #142669;
        }

        &.checkbox {
          margin-top: 1rem;
        }

        i {
          color: #142669;
        }

        input {
          &[type='radio'] {
            display: none;

            &:checked {
              & ~ span {
                &::after {
                  content: '';
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 0.75rem;
                  height: 0.75rem;
                  background: #8bc2de;
                  border-radius: inherit;
                  transform: translate(-50%, -50%);
                }
              }
            }

            & ~ span {
              display: block;
              position: relative;
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 50%;
              margin: 0 0.5rem;
              border: 2px solid #d4d4d4;
            }
          }

          &[type='checkbox'] {
            display: none;

            &:checked {
              & ~ span {
                &::after {
                  content: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 7.5L8.5 14L21 1.5' stroke='white' stroke-width='5'/%3E%3C/svg%3E%0A");
                  background: #8bc2de;
                  position: absolute;
                  width: 100%;
                  display: grid;
                  place-items: center;
                  height: 100%;
                }
              }
            }

            & ~ span {
              display: block;
              position: relative;
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.5rem;
              border: 2px solid #d4d4d4;
            }
          }
        }
      }

      .editUserProfile__link {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
        font-size: 0.8rem;
      }

      .editUserProfile__inputs {
        margin-top: 3rem;
        width: 100%;
        max-width: 45rem;

        small {
          font-size: 0.8rem;
          font-family: 'Mont', Arial, Helvetica, sans-serif;
        }

        .editUserProfile__small {
          font-size: 0.8rem;
          display: block;
          line-height: 1.5;

          a {
            color: #142669;
          }
        }

        h2 {
          font-weight: 700;
          color: #142669;
          font-size: 1.15rem;
          margin-bottom: 1rem;
          margin-top: 1rem;

          &.green {
            color: #2a6359;
          }

          &.mb-n1 {
            margin-bottom: -0.5rem;
          }

          @media (min-width: 768px) {
            font-size: 1.35rem;
          }
        }

        &.photos {
          max-width: unset;

          h4 {
            font-weight: 600;
            text-align: center;
            // max-width: 45rem;
            font-size: 1rem;
            color: #142669;
            margin: 0 auto 2rem auto;
          }

          .editUserProfile__btnPhoto {
            padding-top: 3em;
            border-top: 1px solid #868e96;

            button {
              padding: 0.25em 1.5em;
              width: 100%;
              color: #868e96;
              font-family: 'Mont', Arial, Helvetica, sans-serif;
              font-weight: 700;
              background: transparent;
              outline: none;
              border-radius: 3em;
              border: 1px solid #868e96;
            }
          }
        }

        .editUserProfile__form {
          width: 100%;
        }

        .editUserProfile__details {
          margin-top: -2rem;
          color: #d1d1d1;

          font-size: 0.9rem;

          li,
          h4 {
            color: #d1d1d1;
          }
        }

        .editUserProfile__profile {
          display: flex;
          align-items: center;
          padding: 0 2em 0 0.5em;

          p {
            font-weight: 600;
            font-family: 'Mont', Arial, Helvetica, sans-serif;

            @media (min-width: 768px) {
              margin-left: -3rem;
            }
          }

          .editUserProfile__profileImg {
            display: grid;
            place-items: center;
            width: 10rem;
            height: 10rem;
            object-fit: cover;
            border-radius: 4em;
            margin-bottom: 1rem;
            border: 1px solid #868e96;
            position: relative;

            input {
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
              z-index: 1;
              cursor: pointer;
              opacity: 0;
            }

            svg {
              color: #ced4da;
              font-size: 2.5rem;
            }

            .editUserProfile__UploadImage {
              display: grid;
              place-items: center;
              width: 10rem;
              height: 10rem;
              object-fit: cover;
              border-radius: 4em;
              margin-bottom: 1rem;
              border: 1px solid #868e96;
              position: relative;

              input {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
              }

              svg {
                color: #ced4da;
                font-size: 2.5rem;
              }
            }
          }
        }

        h3 {
          font-size: 1.05rem;
          font-weight: 700;
        }

        h4 {
          font-size: 0.85rem;
          font-weight: 600;
          color: #4f4f4f;
          line-height: 1.6;
          margin-top: 1rem;
        }

        .editUserProfile__listInput {
          display: flex;
          width: 100%;
          align-items: center;
          flex-wrap: wrap;

          .checkbox {
            margin-right: 2rem;
          }
        }

        ul {
          width: 100%;

          li {
            width: 100%;
            font-weight: 600;
            font-size: 0.9rem;

            .signupOnboading__deleteIcon {
              font-size: 1.25rem;
              cursor: pointer;
              margin-top: -0.25rem;
            }
          }
        }
        .knowledge__tag__container {
          margin-top: -1.5rem;
        }
      }

      .subtitle {
        font-weight: 650;
        font-size: 1.15rem !important;
        margin-bottom: 0;
        color: #142669;

        @media (min-width: 768px) {
          font-size: 1.35rem !important;
        }
      }

      .editUserProfile__desc {
        font-family: 'Mont', Arial, Helvetica, sans-serif;
        color: #142669;
        font-weight: 600;
        line-height: 1.8;
        max-width: 48rem;

        &.terms {
          padding-right: 1rem;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: #9993 !important;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #9999 !important;
          }
          height: 70vh;
          max-height: 70vh;
          overflow-y: scroll;
          overflow: -moz-scrollbars-vertical;
          font-weight: 500;
          line-height: 1.6;
          font-size: 1rem;
          margin: 2rem 0;
        }

        .align_center {
          text-align: center;
        }

        b {
          text-align: start;
          font-weight: 700;
        }

        h2 {
          font-weight: 700;
          font-size: 1.15rem !important;
          margin-bottom: 0;
          color: #142669;

          @media (min-width: 768px) {
            font-size: 1.35rem !important;
          }
        }

        span {
          text-decoration: underline;
          cursor: pointer;
          color: #e44f3e;

          &.dark-blue-color {
            color: #142669;
          }
        }
      }
    }

    .error__text {
      color: red;
    }
  }

  .kit-image {
    align-self: center;

    img {
      width: 9rem;
      object-fit: cover;
      height: 9rem;
      border-radius: 3em;
      border: 5px solid #fff;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

      @media (max-width: 768px) {
        margin: auto;
      }

      &.uploading {
        opacity: 0;
      }
    }
  }

  [type='file'] {
    opacity: 0;
    background-color: red;
    position: absolute;
    width: 9rem;
    align-self: center;
    z-index: 2;
    height: 12rem;
  }

  .breederProfile__uploadImg {
    display: grid;
    place-items: center;
    width: 10rem;
    height: 10rem;
    margin: 1rem;
    object-fit: cover;
    border-radius: 4em;
    border: 1px solid #868e96;
    position: relative;

    input {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
    }

    svg {
      color: #ced4da;
      font-size: 2.5rem;
    }
  }
}
