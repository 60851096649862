.personal_info {
  margin-top: 2.5rem !important;
}

.available_us {
  color: #14266980;
  opacity: 50%;
  font-weight: 600;
  font-family: 'Mont';
  font-size: 14px;
}

.ref-code-section {
  min-height: 140px;
}

.do-have-ref-code-text {
  margin-top: 0.8rem !important;
}

.verified {
  color: green;
  white-space: nowrap;
  font-weight: 700;

  &.not {
    color: red;
  }
}

.change-email {
  width: 180px;
  font-size: 0.8rem;
}
