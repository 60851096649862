.discuss-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  position: relative;

  .article__back {
    background: #fff;
    padding: 1em;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    border-radius: 1em;
    color: black;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 600;

    svg {
      margin-right: 0.5rem;
      color: #cc7191;
    }
  }

  @media (min-width: 350px) {
    padding: 20px;
    gap: 10px;
  }

  @media (min-width: 1200px) {
    padding: 40px 30px;
    gap: 20px;
  }

  .feed-card-discussion {
    width: 100%;
    max-width: 740px;
    height: auto;

    background: white;
    padding: 0;
    position: relative;
    box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
    border-radius: 12px;

    :last-child {
      .footer {
        &.closed {
          border-bottom: none;
        }
      }
    }

    .card-heading {
      display: flex;
      justify-content: space-between;
      margin: 0 20px;
      padding: 20px 0 10px;

      &.conversation {
        border-bottom: 1px solid #d0d4e1;
      }

      .kit-image-heading {
        width: 39px;
        height: 39px;
        border-radius: 25px;
        margin-right: 10px;
        object-fit: cover;

        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
        }
      }

      .heading-text-right {
        width: auto;
        height: 39px;

        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;

        display: flex;
        align-items: flex-end;
        text-align: right;

        color: #8993b4;

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        @media (max-width: 768px) {
          align-items: flex-start;
        }
      }

      .heading-text-left-up {
        margin: 0;
        width: 200px;
        height: 20px;

        font-weight: 700;
        font-size: 16px;
        line-height: 160%;

        display: flex;
        align-items: center;
        font-feature-settings: 'liga' off;

        color: #142669;

        flex: none;
        order: 0;
        flex-grow: 0;

        @media (max-width: 768px) {
          width: 150px;
          font-size: 12px;
          height: fit-content;
        }
      }

      .heading-text-left-down {
        margin: 0;
        width: 200px;
        height: fit-content;

        font-weight: 500;
        font-size: 12px;
        line-height: 160%;

        display: flex;
        align-items: flex-end;

        color: #8993b4;

        flex: none;
        order: 1;
        flex-grow: 0;

        @media (max-width: 768px) {
          width: 150px;
          font-size: 10px;
          height: fit-content;
        }
      }
    }

    .image-container {
      position: relative;
      height: 263px;

      .kit-image-wrapper {
        width: 100%;
        height: 263px;
      }

      .kit-image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .play__badge {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        cursor: pointer;
      }
    }

    .article-badge {
      position: absolute;
      left: 0;
      top: 232px;
      padding: 6px 14px;
      width: 86px;
      height: 31px;

      color: #fff;
      background: #cc7191;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 160%;
      border-radius: 0 1em 0 0;
      padding: 0.5em 1em;
      z-index: 3;
    }

    .card-text-area {
      height: auto;
      padding: 20px 20px 0 20px;

      &.is-reply {
        padding: 0 20px 0 20px;
      }

      .card-title-text {
        cursor: pointer;
        margin-bottom: 12px;

        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

        letter-spacing: 0.005em;

        color: #142669;

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      .comment__tags {
        display: flex;
        align-items: center;
        list-style: none;
        flex-wrap: wrap;
        gap: 10px;
        margin: 0;
        padding: 0;
        margin-bottom: 12px;
        cursor: default;

        &.is-reply {
          display: none;
        }

        li {
          height: 24px;
          width: fit-content;
          padding: 4px 12px;
          border-radius: 6px;
          color: #cc7191;

          background: #f5e3e9;

          font-family: 'Mont';
          font-style: normal;
          font-weight: 800;
          font-size: 8px;
          line-height: 120%;
          letter-spacing: 0.14em;
          font-feature-settings: 'liga' off;
          text-transform: uppercase;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }

      .card-content-text {
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        font-feature-settings: 'liga' off;

        color: #142669;

        flex: none;
        order: 1;
        flex-grow: 0;

        &__article {
          margin-bottom: 20px;
        }

        video,
        img,
        svg {
          max-width: -webkit-fill-available;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      margin: 0 20px;
      padding: 10px 0 20px 0;
      gap: 20px;

      &.closed {
        border-bottom: 1px solid #d0d4e1;
      }

      .footer-left {
        display: flex;
        align-items: center;
        padding: 0px;
        gap: 20px;

        height: 25px;

        flex: none;
        order: 0;
        flex-grow: 0;

        span {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 700;

          line-height: 160%;
          display: flex;
          align-items: center;
          font-feature-settings: 'liga' off;

          color: #142669;
        }

        .disable-click {
          pointer-events: none;
          cursor: default;
        }

        .svg-following {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        div {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: 0;

          span {
            margin-right: 6px;
          }
        }
      }

      .reply-btn {
        border-radius: 8px;
      }
    }

    .comment-top-border {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px 0 13px;
      gap: 5px;
      cursor: pointer;

      .icon-arrow {
        width: 30px;
        height: auto;
      }

      .top-border {
        width: 100%;
        height: 1px;
        background: #d0d4e1;
      }
    }

    .comment__bottom {
      background: #fff;
      padding: 0 0 0 0 !important;

      &.reply1 {
        padding: 0 0 0 30px !important;

        @media (max-width: 768px) {
          padding: 0 0 0 10px !important;
        }
      }

      &.reply2 {
        padding: 0 0 0 30px !important;

        @media (max-width: 768px) {
          padding: 0 0 0 30px !important;
        }
      }
    }

    .activity-comments {
      position: relative;
      width: 100%;
      max-width: 60rem !important;

      margin-top: 12px;
      padding: 0 0 0 30px !important;

      @media (max-width: 768px) {
        padding: 0 0 0 10px !important;
      }

      border-radius: 12px;
      background: #fff;

      span {
        margin-left: 10px;
        font-size: 12px !important;
        font-weight: 700;

        line-height: 160%;
        align-items: center;
        font-feature-settings: 'liga' off;

        color: #142669;
      }

      :last-child {
        .footer {
          &.closed {
            border-bottom: none !important;
          }
        }
      }

      .card-heading {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
        padding: 20px 0 10px;

        .kit-image-heading {
          width: 39px;
          height: 39px;
          border-radius: 25px;
          margin-right: 10px;
          object-fit: cover;

          @media (max-width: 768px) {
            width: 30px;
            height: 30px;
          }
        }

        .heading-text-right {
          width: auto;
          height: 39px;

          font-family: 'Mont';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;

          display: flex;
          align-items: flex-end;
          text-align: right;

          color: #8993b4;

          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;

          @media (max-width: 768px) {
            align-items: flex-start;
          }
        }

        .heading-text-left-up {
          margin: 0;
          width: 200px;
          height: 20px;

          font-weight: 700;
          font-size: 16px;
          line-height: 160%;

          display: flex;
          align-items: center;
          font-feature-settings: 'liga' off;

          color: #142669;

          flex: none;
          order: 0;
          flex-grow: 0;

          @media (max-width: 768px) {
            width: 120px;
            font-size: 12px;
          }
        }

        .heading-text-left-down {
          margin: 0;
          width: 200px;
          height: 19px;

          font-weight: 500;
          font-size: 12px;
          line-height: 160%;

          display: flex;
          align-items: flex-end;

          color: #8993b4;

          flex: none;
          order: 1;
          flex-grow: 0;

          @media (max-width: 768px) {
            width: 120px;
            font-size: 10px;
          }
        }
      }

      .image-container {
        position: relative;
        height: 263px;

        .kit-image-wrapper {
          width: 100%;
          height: 263px;
        }

        .kit-image {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .article-badge {
        position: absolute;
        left: 0;
        top: 232px;
        padding: 6px 14px;
        width: 86px;
        height: 31px;

        color: #fff;
        background: #cc7191;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.1em;
        line-height: 160%;
        border-radius: 0 1em 0 0;
        padding: 0.5em 1em;
        z-index: 3;
      }

      .card-text-area {
        height: auto;
        padding: 0 20px 0 20px;

        .card-title-text {
          cursor: pointer;
          margin-bottom: 12px;

          font-weight: 700;
          font-size: 24px;
          line-height: 120%;

          letter-spacing: 0.005em;

          color: #142669;

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .comment__tags {
          display: flex;
          align-items: center;
          list-style: none;
          flex-wrap: wrap;
          gap: 10px;
          margin: 0;
          padding: 0;
          margin-bottom: 12px;
          cursor: default;

          &.is-reply {
            display: none;
          }

          li {
            height: 24px;
            width: fit-content;
            padding: 4px 12px;
            border-radius: 6px;
            color: #cc7191;

            background: #f5e3e9;

            font-family: 'Mont';
            font-style: normal;
            font-weight: 800;
            font-size: 8px;
            line-height: 120%;
            letter-spacing: 0.14em;
            font-feature-settings: 'liga' off;
            text-transform: uppercase;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }

        .card-content-text {
          font-weight: 500;
          font-size: 16px;
          line-height: normal;
          font-feature-settings: 'liga' off;

          color: #142669;

          flex: none;
          order: 1;
          flex-grow: 0;

          &__article {
            margin-bottom: 20px;
          }

          video,
          img,
          svg {
            max-width: -webkit-fill-available;
          }
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        margin: 0 20px;
        padding: 10px 0 20px 0;
        gap: 20px;

        &.closed {
          border-bottom: 1px solid #d0d4e1;
        }

        .footer-left {
          display: flex;
          align-items: center;
          padding: 0px;
          gap: 20px;

          height: 25px;

          flex: none;
          order: 0;
          flex-grow: 0;

          span {
            margin-left: 10px;
            font-size: 12px;
            font-weight: 700;

            line-height: 160%;
            display: flex;
            align-items: center;
            font-feature-settings: 'liga' off;

            color: #142669;
          }

          .disable-click {
            pointer-events: none;
            cursor: default;
          }

          .svg-following {
            font-size: 1rem;
            margin-right: 0.5rem;
          }

          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 0;

            span {
              margin-right: 6px;
            }
          }
        }

        .reply-btn {
          border-radius: 8px;
        }
      }

      .comment-top-border {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 20px 0 13px;
        gap: 5px;
        cursor: pointer;

        .icon-arrow {
          width: 30px;
          height: auto;
        }

        .top-border {
          width: 100%;
          height: 1px;
          background: #d0d4e1;
        }
      }

      .comment__bottom {
        background: #fff;
        padding: 0 0 0 0 !important;
        :last-child {
          .footer {
            &.closed {
              border-bottom: none;
            }
          }
        }

        &.reply1 {
          padding: 0 0 0 30px !important;

          @media (max-width: 768px) {
            padding: 0 0 0 10px !important;
          }
        }

        &.reply2 {
          padding: 0 0 0 60px !important;

          @media (max-width: 768px) {
            padding: 0 0 0 30px !important;
          }
        }

        .footer {
          &.closed {
            border-bottom: 1px solid #d0d4e1 !important;
          }
        }
      }
    }
  }
}

.discussion-card-editor-lvl-1 {
  margin: 20px;
}

.discussion-card-editor-lvl-2 {
  margin: 20px 20px 20px 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d4e1 !important;
}
