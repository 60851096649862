.shopify-success {
  width: 100%;
  background: white;

  .image-container {
    margin-top: 80px;
  }

  .shopify-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 60px;
    background: #fff;
    width: auto;
    max-width: 850px !important;
    height: auto;
    margin: 80px auto;
    border-radius: 24px;

    h4 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 114%;
      text-align: center;
      letter-spacing: 0.005em;
      color: #142669;
    }

    h2 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 114%;

      text-align: center;
      letter-spacing: 0.005em;
      color: #142669;

      margin-bottom: 20px;
      align-self: stretch;
    }

    p {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;

      text-align: center;
      font-feature-settings: 'liga' off;
      color: #142669;
      align-self: stretch;
    }

    .status-info {
      %text {
        font-family: Mont;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;

        font-feature-settings: 'liga' off;
      }

      p {
        @extend %text;
        color: #142669;

        &.error {
          color: #ed5757;
        }
      }

      a {
        @extend %text;
        color: #cc7191;
      }
    }

    .shopify-success-img {
      margin: 20px 0;
    }
  }
}
