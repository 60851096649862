.tabs-admin {
  border-bottom: none;
  padding-bottom: 1em;
  margin-bottom: 0;

  ul {
    display: flex;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #efefef;
    overflow: scroll;
    padding: 0;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 768px) {
      overflow-x: scroll;
    }

    li {
      margin-right: 0.5rem;
      padding: 0.5rem 1rem;
      font-weight: 600;

      color: #142669;
      white-space: nowrap;
      list-style: none;
      cursor: pointer;

      user-select: none;

      :first-child {
        margin-left: 0.5rem;
      }

      :focus {
        border: none;

        background-color: #e8e8e8;
        outline: none;
      }

      &.active {
        color: #cc7190;
        font-weight: 700;
        border-bottom: 2px solid #cc7190;
      }
    }
  }
}
