.breeed_details_container {
  max-width: 1600px;
  margin: auto;

  .breeed_details_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    margin: auto;

    width: 100%;
    max-width: 1600px;
    height: 340px;

    background: #142669;
    border-radius: 0px 0px 60px 60px;

    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
      padding: 10px;
      border-radius: 0px 0px 30px 30px;
    }

    h1 {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 114%;

      letter-spacing: 0.005em;

      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.005em;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 12px;
      }

      &.small {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        font-feature-settings: 'liga' off;
        color: #ffffff;
        cursor: pointer;

        @media (max-width: 768px) {
          font-size: 10px;
        }

        :hover {
          color: #e6b8c8;
          text-decoration: underline;
        }
      }
    }

    .header_left {
      max-width: 1100px;
      width: 75%;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 20px;

      .header_left_links {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        align-self: flex-start;

        @media (max-width: 768px) {
          width: 100%;
          justify-content: space-between;
        }
      }

      @media (max-width: 768px) {
        gap: 10px;
        width: 100%;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;
      }

      p {
        :hover {
          color: #e6b8c8;
          text-decoration: underline;
        }

        @media (max-width: 768px) {
          margin-top: 10px;
        }
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        color: #e6b8c8;

        &:hover {
          color: #cc7191;
          text-decoration: underline;
          opacity: 1 !important;
        }
      }
    }

    .header_right {
      width: 25%;
      height: 260px;
      max-width: 260px;
      border-radius: 130px;
      background: white;

      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        width: 140px;
        height: 140px;
        max-width: 140px;
        width: 100%;
      }

      .header_logo_container {
        width: 75%;
        height: 75%;
        border-radius: 25px;

        .header_logo {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }

  .breed_details_content {
    margin: 0 auto 100px auto;
    width: auto;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
      padding: 10px;
      justify-content: center;
      align-items: center;
    }

    .content_left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      flex-wrap: wrap;
      padding: 0px;

      width: 71%;

      @media (max-width: 1500px) {
        width: 100%;
        padding: 0px 50px;
      }

      @media (max-width: 550px) {
        width: 100%;
        padding: 0px 10px;
      }

      .breed_details_tabs {
        overflow-x: scroll;

        margin: 40px 0 0 0;
        width: -webkit-fill-available;
        max-width: 1000px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        position: sticky;
        top: 0px;
        transition: all 1s;

        .arrow_left {
          display: none;
        }
        .arrow_right {
          display: none;
        }

        @media (max-width: 768px) {
          overflow-x: scroll;
          scrollbar-width: 0px;
          margin: 20px 0 0 0;
          padding-bottom: 5px;

          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }

          // .arrow_left {
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   position: sticky;
          //   left: 0;
          //   top: 0;
          //   height: 100%;
          //   width: 50px;
          //   background: #aab1c9;
          //   opacity: 0.2;
          //   z-index: 100;
          //   cursor: pointer;

          //   &:hover {
          //     opacity: 0.5;
          //   }
          // }
          // .arrow_right {
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   position: sticky;
          //   right: 0;
          //   top: 0;
          //   height: 100%;
          //   width: 50px;
          //   background: #aab1c9;
          //   opacity: 0.2;
          //   z-index: 100;
          //   cursor: pointer;

          //   &:hover {
          //     opacity: 0.5;
          //   }
          // }
        }

        &.colored {
          background: #142669;
          transition: all 1s;
          padding: 20px;
          z-index: 10000;
          border-radius: 0px 0px 20px 20px;
        }

        .breed_details_tab {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: auto;
          cursor: pointer;

          p {
            height: 14px;

            font-family: 'Mont';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 120%;

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            font-feature-settings: 'liga' off;
            margin-bottom: 5px;

            color: #aab1c9;

            &.active {
              &.colored {
                color: #cc7191;
              }
              color: #142669;
            }
          }

          .breed_details_tabline {
            height: 0px;
            width: 100%;
            border: 2px solid #cc7191;
            transform: rotate(179.88deg);

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .content_right {
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      flex-wrap: wrap;
      margin-top: 103px;

      width: 25%;
      min-width: 300px;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 0px;
      }

      h6 {
        margin: 0;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;

        display: flex;
        align-items: center;
        font-feature-settings: 'liga' off;
        color: #142669;
        text-align: center;
      }
    }

    .breed_details_card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 12px;

      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
      border-radius: 12px;

      .left_side_with_img {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column-reverse;
          justify-content: space-between;
        }
      }

      .right_side_with_img {
        display: flex;
        flex-wrap: wrap;
        width: 25%;

        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
        }
      }

      &.blue {
        background: #142669;
        align-items: center;
      }

      &.right {
        align-items: center;
        padding: 0px;
      }

      .breed_details_levels {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 6px;

        width: 30%;
        min-width: 200px;
        min-height: 56px;
        height: auto;

        h6 {
          margin: 0;
          font-family: 'Mont';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;

          display: flex;
          align-items: center;
          font-feature-settings: 'liga' off;
          color: #142669;
        }

        p {
          margin: 0;
        }
      }

      .breed_details_image_container {
        position: relative;
        height: 240px;
        max-width: 240px;
        width: 100%;
        border-radius: 12px;

        &.right {
          max-width: 100%;
          height: auto;
          border-radius: 12px 12px 0 0;
        }

        img {
          width: 100%;
          object-fit: cover;
          border: 1px solid #f4f4f4;
          border-radius: 12px;

          &.right {
            border-radius: 12px 12px 0 0;
            height: 300px;
          }
        }
      }

      .breed_details_gallery {
        position: relative;
        display: flex;
        gap: 0.5rem;
        width: 100%;
        height: 500px;
        overflow: hidden;

        @media (max-width: 768px) {
          height: 300px;
        }

        .arrow_left {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 50px;
          background: #aab1c9;
          opacity: 0.2;
          z-index: 100;
          cursor: pointer;

          &.display_none {
            display: none;
          }

          &:hover {
            opacity: 0.5;
          }
        }
        .arrow_right {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 50px;
          background: #aab1c9;
          opacity: 0.2;
          z-index: 100;
          cursor: pointer;

          &.display_none {
            display: none;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .breed_details_image_container_gallery {
          position: absolute;
          height: 300px;
          width: 100%;
          max-width: 300px;
          border-radius: 12px;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;

          @media (max-width: 768px) {
            height: 300px;
            text-align: center;
          }

          &.hide_left {
            text-align: center;
            transform: translate(-280%, -50%);
            transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
            transition-duration: 0.5s;
          }

          &.hide_right {
            text-align: center;
            transform: translate(280%, -50%);
            transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
            transition-duration: 0.5s;
          }

          &.first_left {
            text-align: center;
            transform: translate(-140%, -50%);
            transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
            transition-duration: 0.5s;
          }

          &.first_right {
            text-align: center;
            transform: translate(140%, -50%);
            transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
            transition-duration: 0.5s;
          }

          &.central {
            height: 500px;
            max-width: 500px;
            text-align: center;
            transform: translateY(-50%);
            transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
            transition-duration: 0.5s;

            @media (max-width: 768px) {
              height: 300px;
              text-align: center;
            }
          }

          img {
            width: -webkit-fill-available;
            object-fit: contain;
            border: 1px solid #f4f4f4;
            border-radius: 12px;

            @media (max-width: 768px) {
              height: 100%;
              width: 100%;
            }

            &.right {
              border-radius: 12px 12px 0 0;
            }
          }
        }
      }

      .breed_details_dots {
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .breed_details_dot {
          cursor: pointer;
          width: 10px;
          height: 10px;
          background: #d0d4e1;
          border-radius: 18px;

          &.colored {
            background: #cc7191;
          }
        }
      }

      .breeding_category_logos {
        width: 46px;
        height: 46px;
      }

      h5 {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

        display: flex;
        align-items: center;
        letter-spacing: 0.005em;

        color: #142669;
      }

      h4 {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 114%;

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.005em;

        color: #ffffff;
      }

      p,
      li {
        width: 100%;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        font-feature-settings: 'liga' off;

        color: #142669;

        &.seventy {
          width: 70%;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        &.bold {
          font-weight: 700;
        }

        &.subhedaline {
          font-size: 20px;
          line-height: 160%;
          color: white;
          text-align: center;
        }
      }
    }

    .breed_details_similar_breeds_container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      gap: 20px;

      h5 {
        margin: 0;
        width: 100%;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #142669;
      }

      .letter_box_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;

        .letter_box {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;

          width: 47px;
          height: 47px;

          background: #ffffff;
          border: 1px solid #d0d4e1;
          border-radius: 8px;

          cursor: pointer;

          @media (max-width: 768px) {
            width: 33px;
            height: 33px;
          }

          &.selected {
            background: #cc7191;

            color: white;
            border: 1px solid #cc7191;
            border-radius: 8px;
          }

          h5 {
            width: auto;
            margin: 0;
            text-align: center;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;

            display: flex;
            align-items: center;
            letter-spacing: 0.005em;

            color: #142669;
          }
        }
      }

      .cards_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        gap: 15px;

        &.similar {
          background: #e6eaed;
          overflow-x: scroll;
          padding: 20px;
          align-items: stretch;
        }

        .card {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 10px;

          width: 246.67px;
          height: 420px;
          background: #ffffff;

          box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
          border-radius: 12px;
          cursor: pointer;

          &.similar {
            min-height: 300px;
            height: auto;
            min-width: 246.67px;
          }

          .card_read_more {
            height: 20px;
            // margin: 0 16px;
            left: 0px;
            bottom: 16px;
            border-bottom: 2px solid #cc7191;
            cursor: pointer;
            margin: 0 16px 16px 16px;

            .text_read_more {
              margin: 0;
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 100%;
              display: flex;
              align-items: center;
              font-feature-settings: 'liga' off;
              color: #cc7191;
            }
          }

          h6 {
            margin: 0 16px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;

            display: flex;
            align-items: center;
            font-feature-settings: 'liga' off;

            color: #142669;
          }

          p {
            margin: 0 16px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 160%;

            font-feature-settings: 'liga' off;

            color: #142669;
          }

          .image_container {
            position: relative;
            height: 240px;
            width: 100%;
            border-radius: 12px 12px 0px 0px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 12px 12px 0px 0px;
            }
          }
        }
      }
    }
  }
}

.breeed_details_loader {
  width: 100%;
  text-align: center;
  margin: 40px 0 calc(100vh - 330px) 0;
}
