.thank-you {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 850px;
  margin: auto;
  padding: 60px;
  gap: 20px;

  %text {
    color: var(--Dark-Neutral, #142669);
    text-align: center;
    font-family: Mont;
    font-style: normal;
    font-weight: 600;
  }

  h2 {
    @extend %text;
    font-size: 48px;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    @media (max-width: 576px) {
      font-size: 32px;
    }
  }

  span {
    @extend %text;
    font-size: 20px;
    line-height: 160%; /* 32px */
    letter-spacing: 0.1px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  img {
    width: 260px;
    height: 260px;
    flex-shrink: 0;
    object-fit: contain;
    margin: 10px 0px;
  }

  .info-text {
    @extend %text;
    font-feature-settings: 'liga' off;
    font-size: 16px;
    line-height: 160%; /* 25.6px */

    &--login {
      @extend %text;
      color: var(--orchid-orchid-100, #cc7191);
      text-decoration-line: underline;
      font-feature-settings: 'liga' off;
      font-size: 16px;
      line-height: 160%;
      cursor: pointer;
    }
  }
}
