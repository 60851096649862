.forgot {
  margin: auto;
  padding: 3em 0;

  & > div:first-child {
    position: relative;
  }

  .forgot__back {
    position: absolute;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Mont', Arial, Helvetica, sans-serif;

    svg {
      color: #cc7191;
      margin-right: 0.5rem;
    }
  }

  .forgot__container {
    max-width: 50rem !important;
    margin-bottom: 10rem;
  }

  h2 {
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
  }

  .password__requirement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.9rem;
    color: #d1d1d1;
    width: 100%;
    padding: 0;

    h4 {
      color: #d1d1d1;
      font-size: 1rem;
    }
  }

  .forgot__label {
    font-size: 12px;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  .forgot__horizontalDivider {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d1d1d1;
    line-height: 0.1em;
    margin: 20px 0 20px;
    color: #d1d1d1;

    span {
      background: #fff;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      padding: 0 10px;
    }
  }

  .devpanel__button {
    color: white;
    background-color: #cc7191;
    border-radius: 18px;
    border: 1px solid transparent;
    font-weight: 600;
    width: 50%;
    height: 50px;

    &.forgot {
      margin-top: 0px;
      color: white;
      padding: 0;
    }

    &.create {
      background-color: white;
      color: #cc7191;
      border: 1px solid #cc7191;
      margin-bottom: 0.5rem;
    }
  }

  .forgot__noAccount {
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
  }

  .forgot__privacyPolicy {
    color: #cc7191;
    cursor: pointer;
    text-decoration: underline;
  }

  .forgot__note {
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    margin-top: 0.5rem;
    display: block;
  }

  .email__group {
    flex-direction: column;
    margin-bottom: 1rem;

    label {
      display: block;
    }

    input {
      width: 70%;
    }

    button {
      margin-top: 0 !important;
      margin-left: 10px;
      width: calc(30% - 10px);
      height: 48px;
    }
  }
}
