.termsandpolicy {
  .termsandpolicy__container {
    padding: 3rem 5rem !important;
    max-width: 1000px;

    @media (max-width: 768px) {
      padding: 3rem !important;
    }

    h6,
    strong {
      font-weight: 700;
      color: #142669;
    }

    p,
    table,
    li {
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      color: #435187;
      overflow-wrap: break-word;
    }
  }
}

.tos-no-bullets {
  list-style: none;
}

.tos-h5 {
  font-size: 1.1rem;
}
