.pawffice_hours {
  box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
  border-radius: 12px !important;
  overflow: hidden;
  background: #fff;
  width: 100%;
  max-width: 380px;
  margin-top: 40px;
  height: fit-content;

  .upcoming_pawffice_hour_heading {
    background-color: #cc7191;
    padding: 8px 20px;
    color: white;
    font-family: Mont;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .card_image_container {
    position: relative;
    display: block;
    height: 11rem;

    .kit-image {
      height: 100%;
      z-index: 2;
      img {
        height: 100%;
        width: 100% !important;
        margin-bottom: 0.5rem;
        object-fit: cover !important;
        border-radius: 0px;
      }
    }
  }

  .pawffice_info_container {
    padding: 1rem;

    .pawffice_name {
      font-family: Mont;
      font-weight: 600;
      font-size: 1.2rem;
      color: #142669;
      margin: 0px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .host_name {
      font-family: Mont;
      font-size: 0.85rem;
      color: #142669;
      font-weight: 600;
      overflow: hidden;
      margin: 2px 0px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .date_text {
      font-family: Mont;
      font-size: 0.7rem;
      color: #142669;
      margin-left: 5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .register_button {
      border-radius: 8px;
    }
  }

  .show_more_less {
    font-family: Mont;
    font-size: 0.8rem;
    color: #142669;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .pawffice_item {
    display: flex;
    flex-direction: column;
    width: 340px;
    margin: 10px 20px 0px 20px;
    cursor: pointer;

    .pawffice_item_info {
      display: flex;
      justify-content: space-between;

      .pawffice_name {
        font-family: Mont;
        font-weight: 600;
        font-size: 0.9rem;
        color: #142669;
        margin: 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .host_name {
        font-family: Mont;
        font-size: 0.8rem;
        color: #142669;
        overflow: hidden;
        margin: 5px 10px 0px 0px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .date_text {
        font-family: Mont;
        font-size: 0.8rem;
        color: #142669;
        margin: 5px 0px 0px 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .register {
        font-family: Mont;
        font-size: 1rem;
        color: #cc7191;
        text-decoration: underline;
        cursor: pointer;
        margin: 0px;
        align-self: flex-end;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #d0d4e1;
      margin-top: 10px;
    }
  }
}
