.banner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 0;
  padding: 20px;
  border-radius: 0 0 12px 12px;
  color: white;

  .banner__title {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 0.005em;
    max-width: 60rem;
    padding: 0;

    @media (min-width: 992px) {
      font-size: 3rem;
    }

    @media (min-width: 768px) {
      font-size: 2.25rem;
    }
  }

  p {
    max-width: 50rem;

    margin: 0 !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    /* or 26px */

    text-align: center;
    font-feature-settings: 'liga' off;

    @media (min-width: 768px) {
      font-size: 14px;
    }

    @media (min-width: 992px) {
      font-size: 16px;
    }
  }

  &.orchid {
    background: linear-gradient(180deg, #cc7191 0, #d17f9c 100%);
  }

  &.green {
    background: linear-gradient(180deg, #7cbda4 0, #66ac91 100%);
  }

  &.lightBlue {
    background: linear-gradient(180deg, #8bc2de 0%, #75b3d2 100%);
  }

  &.blue {
    background: #142669;
  }

  &.darkGreen {
    background: #2a6359;
  }
}

.banner__tags {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 0;
  margin: 0;

  li {
    background: #f5e3e9;
    color: #cc7191;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: default;
    text-align: center;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    font-size: 10px;
    display: block;
    width: fit-content;
    padding: 0.5em 1em;
    border-radius: 0.75em;
  }
}
