.article {
  background: #f3f4f8;
  padding-bottom: 3em;

  .reactions-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    a {
      color: white;
    }

    .image-icon {
      filter: brightness(0) saturate(100%) invert(96%) sepia(2%) saturate(545%) hue-rotate(289deg)
        brightness(116%) contrast(100%);
    }
  }

  .article__hero {
    width: 100%;
    height: 13rem;
    object-fit: cover;
    object-position: center 20%;
  }

  .article__container {
    margin-top: 2rem;
    border-radius: 12px;
    max-width: 60rem !important;
    background: #fff;
    position: relative;

    @media (min-width: 790px) {
      padding: 2em 3em !important;
    }

    .article__commentTitle {
      font-weight: 600;
      color: #142669;
      font-size: 1.25rem;
      margin-right: 0.5rem;
      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .article__back {
      background: transparent;
      width: max-content;
      top: 0;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      display: flex;
      align-items: center;
      color: black;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 600;

      svg {
        margin-right: 0.5rem;
        color: #cc7191;
      }
    }

    .article__socialMedia {
      a {
        display: block;
        height: fit-content;
        width: fit-content;
        background: #e8f3f8;
      }

      top: 0;
      background: #fff;
      padding: 1em;
      left: calc(100% + 1.5rem);
      display: flex;
      align-items: center;
      border-radius: 1em;

      @media (min-width: 1200px) {
        position: absolute;
        flex-direction: column;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.25em;
        height: 40px;
        width: 40px;
        background: rgba(139, 194, 222, 0.2);
        border-radius: 15px;
        margin-bottom: 1rem;
        margin-right: 1rem;

        @media (min-width: 1200px) {
          &:last-of-type {
            margin: 0;
          }
          margin-right: 0;
        }
      }
    }
    .article__article {
      word-wrap: break-word;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      b {
        font-family: 'Mont', Arial, Helvetica, sans-serif;
      }

      a {
        overflow-wrap: break-word;
      }

      h5,
      h2,
      h4 {
        font-weight: 700;
      }

      figure {
        font-size: 0.9rem;
        margin-top: 1rem;
        font-style: italic;
        text-align: center;
      }

      & > div {
        display: block;
        width: 100%;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      }

      img {
        width: fit-content;
        max-width: 100%;
        height: auto;
        border-radius: 1.25em;
        margin-bottom: 1rem;
        object-fit: contain;
      }
    }

    .article__comment {
      background: #fff;
      border-radius: 1em;
      margin-top: 2rem;
    }
  }

  .comment-heading-title {
    position: relative;
    width: 100%;
    max-width: 60rem !important;
    padding: 0;
    height: 36px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 114%;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: #142669;
  }

  .article__head {
    padding-top: 3em;
    padding-bottom: 3em;
    background: #f4faf8;

    .article__headContainer {
      display: flex;
      flex-direction: column-reverse;

      .article__contentImg {
        width: 100%;
        max-width: 28rem;
        height: 28rem;
        object-fit: cover;
        border-radius: 1.25em;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
      }

      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .article__headText {
        width: 100%;
        display: flex;
        max-width: 40rem;
        flex-direction: column;

        h1 {
          font-size: 2.5rem;
          font-weight: 700;
          color: #142669;
          margin-top: 1rem;
          margin-bottom: 2.5rem;

          @media (min-width: 768px) {
            font-size: 2.75rem;
          }

          @media (min-width: 992px) {
            text-align: center;
            margin-top: 0;
            font-size: 3.25rem;
          }
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 600;

          @media (min-width: 768px) {
            font-size: 1.75rem;
          }

          @media (min-width: 992px) {
            margin-left: auto;
            font-size: 2rem;
          }
        }

        p {
          color: #2a6359;
          font-size: 1rem;

          @media (min-width: 768px) {
            font-size: 1.15rem;
          }
          @media (min-width: 992px) {
            text-align: center;
          }
        }

        @media (min-width: 992px) {
          align-items: center;
        }
      }
    }
  }
}

.fit-content {
  width: fit-content !important;
}

.video-comments {
  position: relative;
  width: 100%;
  max-width: 60rem !important;

  margin-top: 12px;
  border-radius: 12px;

  background: #fff;

  span {
    margin-left: 10px;
    font-size: 12px !important;
    font-weight: 700;

    line-height: 160%;
    align-items: center;
    font-feature-settings: 'liga' off;

    color: #142669;
  }

  :last-child {
    .footer {
      &.closed {
        border-bottom: none;
      }
    }
  }

  @media (min-width: 790px) {
    padding: 2em 2em !important;
  }

  .card-heading {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    padding: 20px 0 10px;

    .kit-image-heading {
      width: 39px;
      height: 39px;
      border-radius: 25px;
      margin-right: 10px;
      object-fit: cover;

      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }

    .heading-text-right {
      width: auto;
      height: 39px;

      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;

      display: flex;
      align-items: flex-end;
      text-align: right;

      color: #8993b4;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;

      @media (max-width: 768px) {
        align-items: flex-start;
      }
    }

    .heading-text-left-up {
      margin: 0;
      width: 200px;
      height: 20px;

      font-weight: 700;
      font-size: 16px;
      line-height: 160%;

      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;

      color: #142669;

      flex: none;
      order: 0;
      flex-grow: 0;

      @media (max-width: 768px) {
        width: 120px;
        font-size: 12px;
      }
    }

    .heading-text-left-down {
      margin: 0;
      width: 200px;
      height: 19px;

      font-weight: 500;
      font-size: 12px;
      line-height: 160%;

      display: flex;
      align-items: flex-end;

      color: #8993b4;

      flex: none;
      order: 1;
      flex-grow: 0;

      @media (max-width: 768px) {
        width: 120px;
        font-size: 10px;
      }
    }
  }

  .image-container {
    position: relative;
    height: 263px;

    .kit-image-wrapper {
      width: 100%;
      height: 263px;
    }

    .kit-image {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .article-badge {
    position: absolute;
    left: 0;
    top: 232px;
    padding: 6px 14px;
    width: 86px;
    height: 31px;

    color: #fff;
    background: #cc7191;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 160%;
    border-radius: 0 1em 0 0;
    padding: 0.5em 1em;
    z-index: 3;
  }

  .card-text-area {
    height: auto;
    padding: 20px 20px 0 20px;

    &.is-reply {
      padding: 0 20px 0 20px;
    }

    .card-title-text {
      cursor: pointer;
      margin-bottom: 12px;

      font-weight: 700;
      font-size: 24px;
      line-height: 120%;

      letter-spacing: 0.005em;

      color: #142669;

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    .comment__tags {
      display: flex;
      align-items: center;
      list-style: none;
      flex-wrap: wrap;
      gap: 10px;
      margin: 0;
      padding: 0;
      margin-bottom: 12px;
      cursor: default;

      &.is-reply {
        display: none;
      }

      li {
        height: 24px;
        width: fit-content;
        padding: 4px 12px;
        border-radius: 6px;
        color: #cc7191;

        background: #f5e3e9;

        font-family: 'Mont';
        font-style: normal;
        font-weight: 800;
        font-size: 8px;
        line-height: 120%;
        letter-spacing: 0.14em;
        font-feature-settings: 'liga' off;
        text-transform: uppercase;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .card-content-text {
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      font-feature-settings: 'liga' off;

      color: #142669;

      flex: none;
      order: 1;
      flex-grow: 0;

      &__article {
        margin-bottom: 20px;
      }

      video,
      img,
      svg {
        max-width: -webkit-fill-available;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    margin: 0 20px;
    padding: 10px 0 20px 0;
    gap: 20px;

    &.closed {
      border-bottom: 1px solid #d0d4e1;
    }

    .footer-left {
      display: flex;
      align-items: center;
      padding: 0px;
      gap: 20px;

      height: 25px;

      flex: none;
      order: 0;
      flex-grow: 0;

      span {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;

        line-height: 160%;
        display: flex;
        align-items: center;
        font-feature-settings: 'liga' off;

        color: #142669;
      }

      .disable-click {
        pointer-events: none;
        cursor: default;
      }

      .svg-following {
        font-size: 1rem;
        margin-right: 0.5rem;
      }

      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 0;

        span {
          margin-right: 6px;
        }
      }
    }

    .reply-btn {
      border-radius: 8px;
    }
  }

  .comment-top-border {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 13px;
    gap: 5px;
    cursor: pointer;

    .icon-arrow {
      width: 30px;
      height: auto;
    }

    .top-border {
      width: 100%;
      height: 1px;
      background: #d0d4e1;
    }
  }

  .comment__bottom {
    background: #fff;
    padding: 0 0 0 0 !important;

    &.reply1 {
      padding: 0 0 0 60px !important;

      @media (max-width: 768px) {
        padding: 0 0 0 10px !important;
      }
    }

    &.reply2 {
      padding: 0 0 0 60px !important;

      @media (max-width: 768px) {
        padding: 0 0 0 30px !important;
      }
    }

    .footer {
      &.closed {
        border-bottom: 1px solid #d0d4e1 !important;
      }
    }
  }
}

.video-card-editor-lvl-1 {
  margin: 20px;
}

.video-card-editor-lvl-2 {
  margin: 20px 20px 20px 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d4e1 !important;
}
