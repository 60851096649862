.input-container {
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;

  .currency-sign {
    position: absolute;
    left: 16px;
    color: #142669;
  }

  .currency-input {
    padding-left: 22px;
  }
}
