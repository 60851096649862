.registration-container {
  background: white;
  margin: 100px auto;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px;

  @media (max-width: 992px) {
    margin: 20px auto;
  }

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    &.centered {
      text-align: center;
    }

    span {
      display: inline-block;
      transform-origin: center; /* Adjust as needed */
    }

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  h5 {
    margin: 0 auto;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.1px;

    span {
      font-weight: 800;
    }

    &.centered {
      text-align: center;
    }

    &.with-margin {
      margin: 20px auto 0 auto;
    }

    @media (max-width: 992px) {
      font-size: 14px;

      &.with-margin {
        margin: 10px auto 0 auto;
      }
    }
  }
}

.fill-available {
  width: 100%; /* Fallback for browsers that don't support the below properties */
  width: -webkit-fill-available; /* Chrome, Safari, newer versions of Opera */
  width: -moz-available; /* Firefox */
}

.stepper-container {
  display: flex;
  width: 100%;
  max-width: 850px;
  align-items: center;
  gap: 50px;

  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
    align-self: stretch;
  }

  &__middle {
    position: relative;
    height: 5px;
    flex: 1 0 0;
    border-radius: 15px;
    background-color: #e7e9f0;
    overflow: hidden;

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #cc7191;
      width: 25%;
      transition: width 0.5s;

      // Total steps = 9 [Buyer activate profile step excluded from 9th position]
      &.percent1in9 {
        width: 11.11%; /* 100% divided by 9 */
      }
      &.percent2in9 {
        width: 22.22%;
      }
      &.percent3in9 {
        width: 33.33%;
      }
      &.percent4in9 {
        width: 44.44%;
      }
      &.percent5in9 {
        width: 55.56%;
      }
      &.percent6in9 {
        width: 66.67%;
      }
      &.percent7in9 {
        width: 77.78%;
      }
      &.percent8in9 {
        width: 88.89%;
      }
      &.percent9in9 {
        width: 100%;
      }

      // Total steps = 10 [Buyer activate profile step added at 9th position]
      &.percent1in10 {
        width: 10%; /* 100% divided by 10 */
      }
      &.percent2in10 {
        width: 20%;
      }
      &.percent3in10 {
        width: 30%;
      }
      &.percent4in10 {
        width: 40%;
      }
      &.percent5in10 {
        width: 50%;
      }
      &.percent6in10 {
        width: 60%;
      }
      &.percent7in10 {
        width: 70%;
      }
      &.percent8in10 {
        width: 80%;
      }
      &.percent9in10 {
        width: 90%;
      }
      &.percent10in10 {
        width: 100%;
      }
    }
  }

  %common-left-right {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #142669;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 22.4px */
    letter-spacing: 0.28px;
    text-decoration: none;
  }

  &__left {
    @extend %common-left-right;
    cursor: pointer;
  }

  &__right {
    @extend %common-left-right;
  }
}

.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;

  @media (max-width: 992px) {
    gap: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
  }

  h4 {
    margin: 0;
    align-self: stretch;
    color: #142669;
    text-align: center;
    font-family: Mont;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 36.48px */
    letter-spacing: 0.16px;

    @media (max-width: 992px) {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      letter-spacing: 0.12px;
    }
  }
}

.calendar-square {
  display: flex;
  width: 410px;
  max-width: 410px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 2px solid #d0d4e1;
  cursor: pointer;

  &:hover {
    border: 2px solid #e6b8c8;
  }

  h4 {
    align-self: center;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 992px) {
    width: 250px;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: none;
    height: 100px;
    max-height: 100px;
    padding: 0px 20px;
    gap: 10px;

    img {
      width: auto;
    }
  }
}

.sub-heading-copy {
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
}

.verify-number-description {
  align-self: flex-start;
  width: 70%;
  height: 38px;
  color: #142669;
  font-family: Mont;
  font-size: 12px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.about-program-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 850px;
  gap: 30px;
  margin: 0 auto;

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    &.centered {
      text-align: center;
    }

    span {
      display: inline-block;
      transform-origin: center; /* Adjust as needed */
    }

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  &__info-text {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    line-height: 150%; /* 24px */
    align-self: stretch;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 850px;
    gap: 6px;
    margin: auto;
  }

  @media (max-width: 992px) {
    gap: 20px;
  }
}

.about-program-desktop-offers {
  display: flex;
  gap: 30px;

  @media (max-width: 992px) {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
  }
}

.offer-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  width: 410px;
  height: 100%;
  gap: 22px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid #d0d4e1;
  background: #fff;

  @media (max-width: 992px) {
    width: 344px;
    min-width: 344px;
    max-width: 344px;
  }

  &__premium {
    background: #142669;
  }

  h4 {
    margin: 0;
    color: #142669;
    font-family: Mont;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.16px;

    &.premium {
      color: white;
    }
  }
}

.card-points {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.card-point {
  display: flex;
  gap: 6px;
}

.point {
  margin: 0;
  margin-left: 19px;
  color: #d0d4e1;
  font-feature-settings: 'liga' off;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  &--selected {
    margin-left: 0px;
    color: #142669;
  }

  &__premium {
    margin-left: 0px;
    color: white;
  }
}

.offer-title {
  color: #142669;
  font-family: Mont;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  span {
    color: white;
    font-family: Mont;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
  }

  &__premium {
    color: white;
  }
}

.delete-breed {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  cursor: pointer;

  @media (max-width: 992px) {
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    margin-top: 28px;
  }
}

.more-about-program-container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 100%;
  max-width: 850px;
  gap: 6px;
  margin: auto;
  font-family: Mont;

  @media (max-width: 992px) {
    gap: 20px;
  }

  h2 {
    color: #142669;
    text-align: left;
  }

  p {
    align-self: flex-start;
    color: #142669;
    font-feature-settings: 'liga' off;
    font-size: 18px;
    font-style: normal;
    line-height: 140%; /* 25.2px */
  }

  .textarea {
    min-height: 150px;
    text-indent: 0 !important;
  }
}

.litters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  margin: auto;
  font-family: Mont;

  h2 {
    color: #142669;
    text-align: left;
  }

  p {
    color: #142669;
    font-feature-settings: 'liga' off;
    font-size: 18px;
    font-style: normal;
    line-height: 140%; /* 25.2px */
  }

  .litter-item-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 576px) {
      gap: 40px;
    }

    .litter-item-web {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 12px;
      border: 1px solid var(--navy-navy-20, #d0d4e1);
      background: #fff;

      @media (max-width: 576px) {
        display: none;
      }

      .litter {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;

        &__title {
          color: var(--navy-navy-100, #142669);
          font-family: Mont;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 114%; /* 36.48px */
          letter-spacing: 0.16px;
        }

        &__add-button {
          display: flex;
          height: 32px;
          padding: 0px 20px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 8px;
          background: var(--navy-navy-100, #142669);

          span {
            color: var(--white-white-100, #fff);
            text-align: center;
            font-feature-settings: 'liga' off;
            font-family: Mont;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 19.2px */
          }
        }
      }

      .receive-leads {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        &__text {
          color: var(--navy-navy-100, #142669);
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }
      }
    }

    .litter-item-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      @media (min-width: 576px) {
        display: none;
      }

      h5 {
        color: var(--navy-navy-100, #142669);
        font-family: Mont;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 28.8px */
        letter-spacing: 0.12px;
      }

      .litter-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px;
        align-items: flex-start;
        gap: 5px;
        border-radius: 10px;
        border: 2px solid var(--navy-navy-10, #e7e9f0);
        background: #fff;
        position: relative;

        .litter-content {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;

          %text {
            color: var(--navy-navy-100, #142669);
            font-feature-settings: 'liga' off;
            font-family: Mont;
            font-size: 16px;
            font-style: normal;
            line-height: 160%; /* 25.6px */
          }
          &__lable {
            @extend %text;
            font-weight: 700;
          }

          &__value {
            @extend %text;
            font-weight: 600;
          }
        }

        .receive-leads {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          &__text {
            color: var(--navy-navy-100, #142669);
            font-feature-settings: 'liga' off;
            font-family: Mont;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 25.6px */
          }
        }

        .edit-icon {
          position: absolute;
          right: 0;
          margin-right: 15px;
        }
      }

      &__add-button {
        display: flex;
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: var(--navy-navy-100, #142669);

        span {
          color: var(--white-white-100, #fff);
          text-align: center;
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }
      }
    }
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: 50px;
  }

  .skip-button {
    color: var(--orchid-orchid-100, #cc7191);
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.add-litter {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  max-width: 632px;
  padding: 30px;
  gap: 20px;
  border-radius: 12px;
  background: white;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    h4 {
      color: var(--Dark-Neutral, #142669);
      font-family: Mont;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 114%; /* 36.48px */
      letter-spacing: 0.16px;
      margin: 0px;
    }

    span {
      color: var(--Dark-Neutral, #142669);
      font-family: Mont;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    .litter-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &__heading {
        color: var(--navy-navy-100, #142669);
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */

        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .label-visibility {
      display: none;

      @media (max-width: 767px) {
        display: flex;
      }
    }

    .local-label {
      font-size: 18px;
      margin-bottom: 0px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .receive-leads {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: auto;
      gap: 0px;
      flex: 1 0 0;

      &--text {
        color: var(--navy-navy-100, #142669);
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
      }

      &__container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 16px;
        align-self: stretch;

        &--text {
          flex: 1;
          color: var(--navy-navy-100, #142669);
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
}

.confirmation-popup {
  display: flex;
  width: 100%;
  max-width: 632px;
  height: 50vh;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background: #fff;

  &__content {
    color: var(--Dark-Neutral, #142669);
    text-align: center;
    font-family: Mont;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 36.48px */
    letter-spacing: 0.16px;

    p {
      color: var(--Dark-Neutral, #142669);
      text-align: center;
      font-family: Mont;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 114%; /* 36.48px */
      letter-spacing: 0.16px;
    }
  }
}

.build-profile {
  display: flex;
  width: 100%;
  max-width: 850px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    &.centered {
      text-align: center;
    }

    span {
      display: inline-block;
      transform-origin: center; /* Adjust as needed */
    }

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    span {
      color: var(--navy-navy-80, #435187);
      font-family: Mont;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 32px */
      letter-spacing: 0.1px;
    }
  }

  &__content {
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23D0D4E1' stroke-width='2' stroke-dasharray='12%2c 12' stroke-dashoffset='81' stroke-linecap='round'/%3e%3c/svg%3e");

    .image-conatiner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      align-self: stretch;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      img {
        width: 153px;
        height: 153px;
        flex-shrink: 0;
        border-radius: 100px;
        object-fit: cover;
      }

      .upload-btn {
        display: flex;
        min-height: 50px;
        padding: 10px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--navy-navy-100, #142669);

        span {
          color: var(--white-white-100, #fff);
          text-align: center;
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }
      }
    }
  }
}

.litters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  margin: auto;
  font-family: Mont;

  h2 {
    color: #142669;
    text-align: left;
  }

  p {
    color: #142669;
    font-feature-settings: 'liga' off;
    font-size: 18px;
    font-style: normal;
    line-height: 140%; /* 25.2px */
  }

  .litter-item-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 576px) {
      gap: 40px;
    }

    .litter-item-web {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 12px;
      border: 1px solid var(--navy-navy-20, #d0d4e1);
      background: #fff;

      @media (max-width: 576px) {
        display: none;
      }

      .litter {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;

        &__title {
          color: var(--navy-navy-100, #142669);
          font-family: Mont;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 114%; /* 36.48px */
          letter-spacing: 0.16px;
        }

        &__add-button {
          display: flex;
          height: 32px;
          padding: 0px 20px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 8px;
          background: var(--navy-navy-100, #142669);

          span {
            color: var(--white-white-100, #fff);
            text-align: center;
            font-feature-settings: 'liga' off;
            font-family: Mont;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 19.2px */
          }
        }
      }

      .litters-table {
        margin-top: 1rem;

        &__heading {
          display: flex;
          align-items: center;
          flex: 1 0 0;
          color: var(--navy-navy-100, #142669);
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }

        &__row {
          display: flex;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          padding: 5px 0;

          img {
            width: 12px;
            height: 12px;
            flex-shrink: 0;
          }
        }

        &__divider {
          width: 100%;
          height: 1px;
          background-color: #d0d4e1;
          margin: 5px 0px;

          &--none {
            &:last-of-type {
              display: none;
            }
          }
        }
      }

      .receive-leads {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        &__text {
          color: var(--navy-navy-100, #142669);
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }
      }
    }

    .litter-item-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      @media (min-width: 576px) {
        display: none;
      }

      h5 {
        color: var(--navy-navy-100, #142669);
        font-family: Mont;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 28.8px */
        letter-spacing: 0.12px;
      }

      .litter-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 15px;
        align-items: flex-start;
        gap: 5px;
        border-radius: 10px;
        border: 2px solid var(--navy-navy-10, #e7e9f0);
        background: #fff;
        position: relative;

        .litter-content {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;

          %text {
            color: var(--navy-navy-100, #142669);
            font-feature-settings: 'liga' off;
            font-family: Mont;
            font-size: 16px;
            font-style: normal;
            line-height: 160%; /* 25.6px */
          }
          &__lable {
            @extend %text;
            font-weight: 700;
          }

          &__value {
            @extend %text;
            font-weight: 600;
          }
        }

        .receive-leads {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          &__text {
            color: var(--navy-navy-100, #142669);
            font-feature-settings: 'liga' off;
            font-family: Mont;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 25.6px */
          }
        }

        .edit-icon {
          position: absolute;
          right: 0;
          margin-right: 15px;
        }
      }

      &__add-button {
        display: flex;
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: var(--navy-navy-100, #142669);

        span {
          color: var(--white-white-100, #fff);
          text-align: center;
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }
      }
    }
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: 50px;
  }

  .skip-button {
    color: var(--orchid-orchid-100, #cc7191);
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.add-litter {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  max-width: 632px;
  padding: 30px;
  gap: 20px;
  border-radius: 12px;
  background: white;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    h4 {
      color: var(--Dark-Neutral, #142669);
      font-family: Mont;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 114%; /* 36.48px */
      letter-spacing: 0.16px;
      margin: 0px;
    }

    span {
      color: var(--Dark-Neutral, #142669);
      font-family: Mont;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    .litter-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &__heading {
        color: var(--navy-navy-100, #142669);
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */

        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .label-visibility {
      display: none;

      @media (max-width: 767px) {
        display: flex;
      }
    }

    .local-label {
      font-size: 18px;
      margin-bottom: 0px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .receive-leads {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
      flex: 1 0 0;

      &--text {
        color: var(--navy-navy-100, #142669);
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
      }

      &__container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 16px;
        align-self: stretch;

        &--text {
          flex: 1;
          color: var(--navy-navy-100, #142669);
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
}

.confirmation-popup {
  display: flex;
  width: 100%;
  max-width: 632px;
  height: 50vh;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background: #fff;

  &__content {
    color: var(--Dark-Neutral, #142669);
    text-align: center;
    font-family: Mont;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%; /* 36.48px */
    letter-spacing: 0.16px;

    p {
      color: var(--Dark-Neutral, #142669);
      text-align: center;
      font-family: Mont;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 114%; /* 36.48px */
      letter-spacing: 0.16px;
    }
  }
}

.build-profile {
  display: flex;
  width: 100%;
  max-width: 850px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    h2 {
      color: var(--navy-navy-100, #142669);
      font-family: Mont;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 114%; /* 54.72px */
      letter-spacing: 0.24px;
    }

    span {
      color: var(--navy-navy-80, #435187);
      font-family: Mont;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 32px */
      letter-spacing: 0.1px;
    }
  }

  &__content {
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23D0D4E1' stroke-width='2' stroke-dasharray='12%2c 12' stroke-dashoffset='81' stroke-linecap='round'/%3e%3c/svg%3e");

    .image-conatiner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      align-self: stretch;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      img {
        width: 153px;
        height: 153px;
        flex-shrink: 0;
        border-radius: 100px;
        object-fit: cover;
      }

      .upload-btn {
        display: flex;
        min-height: 50px;
        padding: 10px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--navy-navy-100, #142669);

        span {
          color: var(--white-white-100, #fff);
          text-align: center;
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
        }
      }
    }
  }
}

.hear-about-us {
  display: flex;
  width: 100%;
  max-width: 630px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  .textarea {
    min-height: 150px;
  }
}

.form-labels {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  font-feature-settings: 'liga' off;
  color: #142669;
}

.receive-leads {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 10px;
  flex: 1 0 0;

  %leads-text {
    margin: 0;
    color: #142669;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    line-height: 160%; /* 19.2px */
  }

  &__title {
    @extend %leads-text;
    font-weight: 700;
  }

  &__desc {
    @extend %leads-text;
    width: 75%;
    font-weight: 600;
  }
}

.add-breed {
  margin: 24px 12px 0 12px;
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px dashed #d0d4e1;
  cursor: pointer;

  p {
    margin: 0;
    color: #142669;
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
}

.password-validation-notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  gap: 7px;
}

.password-note {
  color: #435187;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin: 0;

  &--match {
    color: #81b29a;
  }
}

.terms-and-privacy-text {
  color: #142669;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */

  &__link {
    color: #cc7191;
    text-decoration-line: underline;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.footer-question {
  margin: 0;
  display: flex;
  gap: 10px;
  color: #142669;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  text-align: center;

  &--disabled {
    pointer-events: none;
  }

  &__link {
    color: #cc7191;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.check-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 850px;
  gap: 20px;
  margin: 0 auto;

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    &.centered {
      text-align: center;
    }

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  .text {
    margin: 0;
    color: #142669;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    text-align: center;

    &--disabled {
      pointer-events: none;
    }

    &__link {
      color: #cc7191;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

.breeder-subscription-img {
  max-width: none;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.form-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 0;

  .no-padding {
    padding: 0 0;
  }

  &.centered {
    justify-content: center;
  }

  button {
    width: 200px !important;
  }

  @media (max-width: 768px) {
    justify-content: center;

    button {
      width: 100% !important;
    }
  }
}

.vouched-description {
  display: none;
}

.vouch-verify {
  font-family: Mont !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #142669 !important;
  font-feature-settings: 'liga' off;
  text-decoration: none !important;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  a {
    color: #8bc2de !important;
  }
  button {
    width: 250px;
    height: 45px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 16px;
    background-color: #142669 !important;
    margin-top: -5px;
    border-radius: 18px;

    span {
      font-family: 'Mont' !important;
      font-weight: 600 !important;
      font-style: normal !important;
      font-size: 16px !important;
      color: white !important;
    }
  }

  .handoff-title {
    display: none;
  }

  div {
    font-family: Mont !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #142669 !important;
    font-feature-settings: 'liga' off;
    text-decoration: none !important;
  }

  span {
    font-family: 'Mont' !important;
    color: #cc7191 !important;
    font-feature-settings: 'liga' off;
    font-size: 15px !important;
    text-decoration: none !important;
  }

  input {
    height: 30px;
    padding: 5px 12px !important;
    margin: 25px 0 0 0 !important;
    border-bottom: none;
    ::before {
      content: none !important;
    }
  }

  .MuiInputBase-root {
    ::before {
      position: absolute;
      top: 45px;
      font-family: Mont !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #cc7191 !important;
      content: 'Enter your number without any dashes';
    }
  }

  .MuiInputBase-formControl:before {
    border-bottom: none !important;
  }
}

.puppymill-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
  max-width: 850px;
  gap: 20px;
  margin: auto;
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__inner {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 280px;

    &::before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 56.25%;
    }
  }

  &__image {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 280px;
    height: 280px;

    img {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.text-transition-item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 4em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;
}

.fractal-animation {
  height: 100%;
}

.parallax-container {
  top: 0;
  height: 500px !important;
  width: 100vw !important;

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
}

.parallax-image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70%;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 40%;
  }

  &__2 {
    @media (max-width: 768px) {
      top: 55%;
      width: 80%;
      height: 80%;
    }
  }

  &__line0 {
    position: absolute;
    left: 50vw;
    top: 45%;

    @media (max-width: 768px) {
      top: 35%;
    }
  }

  &__line1 {
    position: absolute;
    left: 60vw;
    top: 45%;

    @media (max-width: 768px) {
      top: 35%;
    }
  }

  &__line2 {
    display: none;
  }

  &__white2 {
    background-color: white;
    width: 50%;
    height: 70%;
    margin-left: 50%;
    margin-top: 8%;

    @media (max-width: 768px) {
      margin-top: 35%;
    }
  }
}

.falling-path {
  animation: var(--animation-name) 6s linear infinite;
}

.buyer-breeds-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  &.profile {
    width: 100%;
    gap: 10px;
  }
}

.breed-info-container {
  position: relative;
  height: 232px;
  min-height: 232px;
  width: 100%;
  display: flex;
  padding: 20px;
  gap: 30px;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d0d4e1;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 350px;
  }
}

.breed-info {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  width: 100%;

  h4 {
    color: #142669;
    font-family: Mont;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.16px;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 140%; /* 25.2px */
    }
  }
}

.close-breed-img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.add-breed-img {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.breed-info-text-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
}

.breed-info-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }

  &__centered {
    justify-content: center;
  }

  &__left {
    width: 100%;
    align-self: flex-start;
  }

  p {
    margin-bottom: 0;
    color: #142669 !important;
    font-feature-settings: 'liga' off;

    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */

    @media (max-width: 768px) {
      font-size: 14px;
    }

    b {
      font-weight: 700;
    }
  }
}

.breed-info-img {
  width: 200px;
  height: 183px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.img-cover {
  object-fit: cover !important;
}

.similar-breeds-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 20px;
  margin-top: 16px;

  h5 {
    width: 100%;
    color: #142669;

    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.12px;

    @media (max-width: 768px) {
      font-size: 18px;
      font-weight: 700;
      line-height: 140%; /* 25.2px */
    }
  }
}

.similar-breeds {
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-radius: 12px;

  @media (max-width: 768px) {
    gap: 10px;
  }
}

.similar-breed {
  position: relative;
  width: 190px;
  min-width: calc(25% - 22.5px);
  max-width: calc(25% - 22.5px);
  min-height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #e7e9f0;
  background: #fff;

  h6 {
    margin: 0;
    padding: 16px;
    text-align: center;
    color: #142669;
    text-align: center;
    font-feature-settings: 'liga' off;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  @media (max-width: 768px) {
    width: 160px;
    min-width: calc(50% - 5px);
    max-width: calc(50% - 5px);
  }
}

.similar-breed-image {
  height: 148px;
  width: 148px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.similar-breeds-dots {
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.similar-breeds-dot {
  cursor: pointer;
  width: 10px;
  height: 10px;
  background: #d0d4e1;
  border-radius: 18px;

  &.colored {
    background: #cc7191;
  }
}

.did-you-know {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  background: #e8f3f8;
  color: #142669;

  h6 {
    text-align: center;
    font-feature-settings: 'liga' off;

    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }

  p {
    text-align: center;
    font-feature-settings: 'liga' off;

    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
  }
}

.six-digit-verify {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.digit-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f4f8;
  width: 95px;
  height: 95px;
  padding: 20px 25px;
  border-radius: 12px;

  &--active {
    background: white;
  }

  color: #142669 !important;
  margin: 0;
  font-family: Mont;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.005em;

  @media (max-width: 768px) {
    width: 65px;
    height: 65px;
    padding: 10px 14px;

    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.005em;
  }
}

.iti {
  width: 100%;
}

.buyer-active-profile {
  max-width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    &.centered {
      text-align: center;
    }

    span {
      display: inline-block;
      transform-origin: center; /* Adjust as needed */
    }

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  span {
    color: var(--Navy-Navy---80, #435187);
    text-align: center;
    font-family: Mont;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.1px;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .breeders {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 5px;
    position: relative;

    @media (max-width: 330px) {
      gap: 0px;
    }

    &__arrows {
      position: absolute;
      margin-top: 30px;
      z-index: 1;

      @media (max-width: 780px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__breeder-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 210px;
      overflow: hidden;
      gap: 9px;
      z-index: 2;

      img {
        width: 150px;
        height: 150px;
        border-radius: 75px;

        @media (max-width: 600px) {
          width: 125px;
          height: 125px;
        }

        @media (max-width: 450px) {
          width: 105px;
          height: 105px;
        }

        @media (max-width: 330px) {
          width: 85px;
          height: 85px;
        }
      }

      &__name {
        color: #142669;
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;

        @media (max-width: 600px) {
          font-size: 15px;
        }

        @media (max-width: 330px) {
          font-size: 13px;
        }
      }

      &__location {
        color: #435187;
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 30%;

        @media (max-width: 600px) {
          font-size: 12px;
        }

        @media (max-width: 330px) {
          font-size: 10px;
        }
      }

      &--margin {
        margin-top: 105px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: -120px;

    @media (max-width: 600px) {
      margin-top: -140px;
    }

    @media (max-width: 450px) {
      margin-top: -160px;
    }

    @media (max-width: 330px) {
      margin-top: -180px;
    }

    img {
      width: 250px;
      height: 250px;
      object-fit: cover;
    }

    .active-profile-button {
      color: #fff;
      text-align: center;
      font-feature-settings: 'liga' off;
      font-family: Mont;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }

    span {
      color: #142669;
      text-align: center;
      font-feature-settings: 'liga' off;
      font-family: Mont;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      text-decoration: underline;
      text-decoration-color: #cc7191;
      cursor: pointer;
    }
  }
}
