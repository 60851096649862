.login {
  height: max-content;
  width: 100%;

  .image-container {
    margin-top: 80px;
  }

  .login-img {
    margin-top: 80px;
  }

  & > div:first-child {
    position: relative;
  }

  .login__back {
    position: absolute;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    @media (max-width: 768px) {
      top: -30px;
    }
    svg {
      color: #cc7191;
      margin-right: 0.5rem;
    }
  }

  .login__container {
    background: #fff;
    width: auto;
    max-width: 750px !important;
    height: auto;
    margin: 0 auto 80px auto;
    border-radius: 24px;

    h4 {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 114%;

      text-align: center;
      letter-spacing: 0.005em;
      color: #142669;

      margin-bottom: 20px;
    }
  }

  .login__input {
    border: 1px solid #bdbdbd;
    border-radius: 18px;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    height: 48px;
    width: 100%;
    text-indent: 10px;

    &:active {
      border-radius: 18px;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus {
      box-shadow: 0 0 5px #51cbee;
      border: 1px solid #51cbee;
    }
  }

  .login__label {
    font-size: 12px;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  .login__horizontalDivider {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d1d1d1;
    line-height: 0.1em;
    margin: 20px 0 20px;
    color: #d1d1d1;

    span {
      background: #fff;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      padding: 0 10px;
    }
  }

  .login__button {
    color: white;
    background-color: #cc7191;
    border-radius: 18px;
    border: 1px solid transparent;
    font-weight: 600;

    &.login {
      margin-top: 4px;
      color: white;
      padding: 0;
    }
  }

  .forgot-password {
    margin: 20px 0 30px 0;
    text-align: left;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 176.5%;
    font-feature-settings: 'liga' off;
    color: #435187;
  }

  .login__no-account {
    margin: 20px 0 0 0;
    text-align: center;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 176.5%;
    font-feature-settings: 'liga' off;
    color: #435187;
  }

  .login__privacy-policy {
    color: #cc7191;
    cursor: pointer;
    text-decoration: underline;
  }

  .signup__noAccount {
    margin: 30px 0 20px 0;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;

    text-align: center;
    font-feature-settings: 'liga' off;

    color: #142669;
  }
}

.loading-dots-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  font-size: 30px;
  color: #cc7191;

  .loading-dot {
    font-size: 40px;
    animation: dotFlashing 0.8s infinite linear alternate;
    margin-left: 10px;
    margin-top: -10px;
  }

  .loading-dot-1 {
    animation-delay: 0s;
  }
  .loading-dot-2 {
    animation-delay: 0.2s;
  }
  .loading-dot-3 {
    animation-delay: 0.4s;
  }

  @keyframes dotFlashing {
    0% {
      opacity: 0;
    }
    50%,
    100% {
      opacity: 1;
    }
  }
}

.resend-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 850px;
  gap: 20px;
  margin: 100px auto;
  padding: 20px;

  @media (max-width: 992px) {
    margin: 20px auto;
  }

  h2 {
    margin: 0;
    width: 100%;
    align-self: stretch;
    color: #142669;
    font-family: Mont;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 114%; /* 54.72px */
    letter-spacing: 0.24px;

    &.centered {
      text-align: center;
    }

    span {
      display: inline-block;
      transform-origin: center; /* Adjust as needed */
    }

    @media (max-width: 992px) {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.16px;
    }
  }

  .text {
    margin: 0;
    color: #142669;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    text-align: center;

    &--disabled {
      pointer-events: none;
    }

    &__link {
      color: #cc7191;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

.footer-question {
  margin: 0;
  display: flex;
  gap: 10px;
  color: #142669;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  text-align: center;

  &--disabled {
    pointer-events: none;
  }

  &__link {
    color: #cc7191;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
