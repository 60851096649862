footer {
  border-top: 1px solid #ebebeb;
  background: white;
  font-family: 'Mont';
  padding: 30px;

  .footer__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 30px 20px 30px;
    gap: 20px;

    @media (max-width: 768px) {
      padding: 1em;
      padding-top: 2.5em;
    }

    .footer__logo {
      width: 200px;
      height: 45px;
      cursor: pointer;
    }

    .footer__social__media {
      .footer-social-image {
        filter: brightness(0) saturate(100%) invert(10%) sepia(39%) saturate(5452%)
          hue-rotate(224deg) brightness(94%) contrast(96%);
      }

      a {
        display: block;
        height: fit-content;
        width: fit-content;
        border-radius: 8px;
        margin: 0;
      }
    }

    span {
      font-size: 1rem;
      font-weight: 700;
      color: #000;
      display: block;
      font-family: 'Mont';
      margin-bottom: 0.6rem;
    }

    a {
      font-size: 1rem;
      font-weight: 500;
      color: #000;
      text-decoration: none;
      font-family: 'Mont';
      margin-top: 0.25rem;
      transition: all 0.15s ease;

      img {
        width: 20px;
        height: 20px;
        margin: 0.5rem;
        object-fit: contain;
      }
    }

    .link__text {
      color: #000;
      margin-bottom: 0.6rem;
      font-size: 1rem;
    }

    .footer__contact {
      margin: 0 !important;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      font-feature-settings: 'liga' off;

      color: #000000;

      @media (min-width: 992px) {
        white-space: nowrap;
      }
    }
  }

  .divider-vertical {
    height: 15px;
    width: 1px;
    margin: auto 0;
    background-color: #142669;
  }

  .footer__copyright {
    text-decoration: none;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    text-align: center;

    letter-spacing: 0.02em;
    font-feature-settings: 'liga' off;

    padding: 0 12px;

    color: #142669;

    @media (min-width: 992px) {
      white-space: nowrap;
    }
  }
}
