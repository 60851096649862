.non_tt_breeder {
  input:not([type='input']) {
    margin-bottom: 0px;
    border: none;
    color: #142669;
  }

  .nttb_checkbox {
    font-weight: 600;
    color: #142669;
    font-size: 1rem;
    margin: 1rem 0rem;
  }
}
