.sun-editor {
  border-radius: 10px !important;

  .se-container {
    border-radius: 10px !important;
  }
  .se-toolbar {
    border-radius: 10px 10px 0 0 !important;
    padding-right: 60px;
  }
  .se-resizing-bar {
    border-radius: 0 0 10px 10px !important;
  }
}

.reply-btn-sun-editor {
  border-radius: 8px;
  font-family: 'Mont';
  font-style: normal;
  font-size: 12px !important;
  line-height: 160%;

  color: #8993b4;
}

.emoji-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  border: 1px solid #dadada !important;
  border-radius: 4px;
  line-height: 15px;
}
