.dropdown {
  button {
    &.dropdown-toggle {
      width: 100%;
      text-align: left;
      background: transparent;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      color: #bdbdbd;
      padding: 0;
      border: none;
      font-size: 0.9rem;
      display: flex;
      overflow: hidden;
      outline: none;
      border-radius: 0;
      align-items: center;
      color: #142669;
      font-weight: 700;
      justify-content: space-between;
      box-shadow: none;

      &.active,
      &.focus {
        color: #000;
      }

      svg {
        color: #8bc2de;
        font-size: 1.5rem;
      }

      &::after {
        display: none;
      }

      @media (min-width: 768px) {
        font-size: 1.15rem;
      }

      & ~ .dropdown-menu {
        width: 100%;
        height: fit-content;
        max-height: 9rem;
        overflow: hidden;
        overflow-y: scroll;

        .dropdown-item {
          &.active {
            background: #cc7191;
          }
        }
      }

      &.small {
        font-size: 1rem;
        color: #bdbdbd;

        &.active,
        &.focus {
          color: #333;
        }
      }
    }
  }
}
