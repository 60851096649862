.breed_directory__filtersContainer {
  position: sticky;
  top: 0;
  margin-left: 0;
  margin-top: 0;
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 320px;
  min-width: 320px;
  max-width: 320px;
  height: 100vh;

  overflow-y: auto;

  background: #ffffff;

  box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 10px;
    position: relative;
    max-width: none;
  }

  .filter_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    width: 100%;

    h5 {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      display: flex;
      align-items: center;
      letter-spacing: 0.005em;

      color: #142669;
    }

    p {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 160%;

      display: flex;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      font-feature-settings: 'liga' off;

      color: #cc7191;
    }
  }

  .filter_category_name_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d0d4e1;
    margin-bottom: 1rem;
  }

  .filter_category_name {
    margin-bottom: 5px;
    height: 26px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;

    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;

    color: #142669;
  }

  &.subfilter {
    overflow-y: hidden;
    box-shadow: none;

    padding: 0px 0px 0px 34px;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
  }

  .single-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .paw_container {
      display: flex;
      cursor: pointer;

      &.disable_events {
        pointer-events: none;
      }
    }

    &.subfilter {
      margin-bottom: 1rem;

      .checkbox {
        font-size: 12px;
        line-height: 160%;
        font-weight: 600;

        color: #142669;
      }
    }

    &.characteristics {
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        margin-bottom: 5px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;

        font-feature-settings: 'liga' off;

        color: #142669;
      }
    }
  }
}
