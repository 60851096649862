.checkbox {
  display: flex;
  align-items: center;
  margin-top: 0;

  color: #142669;

  font-size: 1rem;
  font-weight: 700;
  font-family: 'Mont', Arial, Helvetica, sans-serif;
  line-height: 1rem;

  cursor: pointer;

  input {
    display: none;

    & ~ span {
      display: block;
      position: relative;
      min-width: 1.25rem;
      min-height: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
      border: 1.5px solid #d0d4e1;
      border-radius: 4px;
    }

    &:checked {
      & ~ span {
        background: #cc7191;
        border: 1.5px solid #cc7191;
        border-radius: 4px;

        &::after {
          background: #cc7191;
          content: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 7.5L8.5 14L21 1.5' stroke='white' stroke-width='5'/%3E%3C/svg%3E%0A") !important;
          position: absolute;
          width: 100%;
          display: grid;
          place-items: center;
          height: 100%;
        }
      }
    }
  }

  &.semi-checked {
    input {
      display: none;

      & ~ span {
        border: 1.5px solid #cc7191;
        border-radius: 4px;

        &::after {
          content: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 7.5L8.5 14L21 1.5' stroke='rgb(204,113,144)' stroke-width='5'/%3E%3C/svg%3E%0A") !important;
          display: grid;
          height: 100%;
          place-items: center;
          position: absolute;
          width: 100%;
        }
      }
    }
  }
}
