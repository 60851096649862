@import './services/themes/_colors.scss';

body {
  width: 100%;
  background: white;
  margin: 0;
  font-family: 'Mont';
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) {
  .container {
    max-width: 94rem;
    padding: 0 4em;
  }
}

button,
p,
span,
input,
li a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Mont', Arial, Helvetica, sans-serif;
}

input {
  height: 48px;
  width: 100%;
  border-radius: 12px !important;
  border: 1px solid $Navy-20 !important;
  text-indent: 10px;
  background: white;
  color: $Navy !important;

  &::placeholder {
    color: $Navy-50 !important;
  }

  &:hover {
    color: $Navy !important;
    border: 1px solid $Orchid-50 !important;
    box-shadow: none !important;
  }

  &:disabled {
    background: $Navy-05 !important;
    color: $Navy-50 !important;
    border: 1px solid $Navy-20 !important;
    box-shadow: none !important;
  }

  &:focus {
    color: $Navy;
    border: 1px solid #d68ea7 !important;
    box-shadow: none !important;
  }
}

input[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}

.input-error {
  input {
    border: 1px solid #ed5757;
  }
}

.tt-primary-icons {
  background-color: transparent !important;
  color: $White;
}

.form-labels {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  font-feature-settings: 'liga' off;
  color: $Navy;
}

// text styles
.text-error {
  height: 1rem;
  font-size: 0.75rem;
  color: #ed5757;
  font-family: 'Mont', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.tt-hover {
  &:hover {
    opacity: 0.6 !important;
    cursor: pointer !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// loading and animation
.loading-animation {
  animation: loading 1s infinite;
}

.svg-loading {
  font-size: 1.75rem;
  margin-top: -0.25rem;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

// selectors
.tt-select {
  &__control {
    border-color: $Navy-20 !important;

    &:hover {
      border-color: $Orchid-50 !important;
    }
  }

  &__input {
    border: none !important;
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}

// additional styles
.react-datepicker-popper {
  z-index: 9999 !important;
}

// To fix Google Autocomplete Suggestions does not apprear in modals.
.pac-container {
  z-index: 10000 !important;
}
