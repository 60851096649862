/* Light Theme Colors */

// Primary colors
$Orchid: #cc7191;
$Orchid-80: #d68ea7;
$Orchid-50: #e6b8c8;
$Orchid-20: #f5e3e9;

$Blue: #8bc2de;
$Blue-80: #b3d7e9;
$Blue-50: #c5e0ef;
$Blue-20: #e8f3f8;

$LightGreen: #81b29a;
$LightGreen-80: #9ac1ae;
$LightGreen-50: #c0d9cc;
$LightGreen-20: #e6f0eb;

// Secondary colors
$DarkGreen: #2a6359;
$DarkGreen-80: #55827a;
$DarkGreen-50: #95b1ac;
$DarkGreen-20: #d4e0de;

$Yellow: #f4ce6c;
$Yellow-80: #f7d88a;
$Yellow-50: #fae7b6;
$Yellow-20: #fdf5e2;

// Neutral colors
$White: rgba(255, 255, 255, 1);
$White-80: rgba(255, 255, 255, 0.8);
$White-50: rgba(255, 255, 255, 0.5);
$White-20: rgba(255, 255, 255, 0.2);
$White-10: rgba(255, 255, 255, 0.1);

$Navy: #142669;
$Navy-80: #435187;
$Navy-50: #8993b4;
$Navy-20: #d0d4e1;
$Navy-10: #e7e9f0;
$Navy-05: #f3f4f8;
$Navy-03: #f8f9fb;

$Black: #000000;
$Black-80: rgba(0, 0, 0, 0.8);
$Black-50: rgba(0, 0, 0, 0.5);
$Black-20: rgba(0, 0, 0, 0.2);
