.newPost {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
  gap: 14px;
  color: #142669;
  width: 100%;
  height: auto;
  z-index: 10000;

  &.no-margin-bottom {
    margin: 0 !important;
  }
  &.padding-bottom {
    padding-bottom: 18rem !important;
  }

  .newPost__heading {
    margin: 0 0 2rem 0;
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    gap: 14px;
  }

  .kit-image {
    margin: 0;

    img {
      width: 3.2rem !important;
      height: 3.2rem !important;
      border-radius: 1rem;
      object-fit: cover;
    }
  }
  .newPost__container {
    width: 100%;
    margin: 0;
    height: auto;

    @media (max-width: 992px) {
      padding-top: 4rem;
    }

    .alert__success {
      position: sticky;
      top: 1rem;
      z-index: 10;
      text-align: center;
    }

    .newPost__title {
      margin-bottom: 2rem;
      font-weight: 600;
    }

    .newPost__label {
      font-size: 12px;
      font-family: 'Mont', Arial, Helvetica, sans-serif;
      font-weight: 600;
      margin-bottom: 0.5rem;
      display: block;
    }

    .newPost__input {
      border: 1px solid #d0d4e1;
      border-radius: 16px;
      width: 100%;
      padding: 0.75em 0.5em;
      text-indent: 10px;
      font-family: 'Mont', Arial, Helvetica, sans-serif;

      &:active {
        border-radius: 18px;
      }

      &:focus-visible {
        outline: none;
      }

      &:focus {
        box-shadow: 0 0 5px #d0d4e1;
      }
    }

    textarea.newPost__input {
      height: 6rem;
      resize: none;
    }

    .newPost__richText {
      margin-top: -2rem;
      margin-bottom: 2rem;

      & ~ span {
        display: block;
        margin-top: -1rem;
      }
    }

    .newPost__bottomRow {
      display: flex;
      justify-content: space-between;
      line-height: 12px;
      font-size: 12px;
      font-weight: 500;

      .newPost__checkboxCol {
        padding-top: 12px;
        font-family: 'Mont', Arial, Helvetica, sans-serif;
        width: 40%;
      }

      .newPost__buttonsBottom {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        button {
          border-radius: 8px;
          line-height: 12px;
          font-size: 12px !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  .newPost__back {
    padding-top: 2em;
    margin-bottom: -2rem;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Mont', Arial, Helvetica, sans-serif;

    @media (max-width: 992px) {
      padding-top: 3em;
      margin-bottom: -2.5em;
    }

    svg {
      color: #cc7191;
      margin-right: 0.5rem;
    }
  }
}
