.deal_details {
  display: flex;
  justify-content: center;

  .deal_container {
    box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
    border-radius: 1rem !important;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    width: 550px;
    margin: 3rem 0rem;

    .deal_back {
      margin: 8px;

      @media (min-width: 750px) {
        position: absolute;
        z-index: 10;
        margin-left: -80px;
      }

      svg {
        color: #cc7191;
        margin-right: 8px;
      }
    }

    .deal_image_container {
      position: relative;
      display: block;
      height: 250px;
      overflow: hidden;

      img {
        width: 100% !important;
        height: 100%;
        margin-bottom: 0.5rem;
        object-fit: cover !important;
        border-radius: 0px;
      }
    }

    .deal_info_container {
      padding: 1rem 2rem;
    }

    .vendor_info_container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      .vendor_logo {
        border-radius: 15px;
        width: 25px;
        height: 25px;
      }

      .vendor_name {
        font-family: Mont;
        font-size: 0.8rem;
        color: #142669;
        font-weight: 600;
        margin-left: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .deal_title {
      font-weight: 600;
      font-size: 1.5rem;
      color: #142669;
      text-align: center;
      margin: 10px 0px 0px 0px;
    }

    .deal_description {
      font-size: 0.8rem;
      color: #142669;
      margin: 10px 40px 0px 40px;
      text-align: center;
    }

    .divider {
      width: '100%';
      height: 1px;
      background: #d9d9d9;
      margin-top: 20px;
    }

    .discount_code_label {
      font-size: 0.9rem;
      font-weight: 600;
      text-align: center;
      color: #142669;
      margin: 20px 0px 5px 0px;
    }

    .discount_code {
      font-weight: 600;
      font-size: 1.8rem;
      text-align: center;
      color: #142669;
      background-color: #f3f4f8;
      padding: 5px;
      text-transform: uppercase;
    }
  }
}
