.default-kit-image {
  position: relative;
  height: fit-content;
  margin-right: auto;
  margin-left: auto;

  img {
    z-index: 1;
    position: relative;
  }

  svg {
    position: absolute;
    top: calc(50% - 0.875rem);
    left: calc(50% - 0.875rem);
    z-index: 0;
  }
}
