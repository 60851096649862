.learn {
  padding-bottom: 3em;
  background: #f8f9fb;

  .filters {
    @media (max-width: 1200px) {
      display: none;
    }

    position: sticky;
    top: 0;
    margin-top: -40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0;
    border-radius: 0 0 12px 12px;

    background: #142669;
    z-index: 10;

    .filter-card {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 12px 7px;
      gap: 10px;
      z-index: 10;

      width: 145px;
      height: 65px;

      background: #142669;
      color: white;
      border: 2px solid #fff;
      box-shadow: 0px 0px 25.1111px rgba(20, 38, 105, 0.1);
      border-radius: 13px;

      cursor: pointer;
      &.active {
        background: #fff;
        color: #142669;
      }
    }
  }

  .learn__content {
    background: #f8f9fb;
    max-width: 1400px;
    margin: 2rem auto auto auto;

    &__upgrade {
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #e7e9f0;

      @media (max-width: 1200px) {
        margin-top: 30px;
      }

      &__heading {
        color: var(--Navy-Navy---100, #142669);
        text-align: center;
        font-feature-settings: 'liga' off;
        font-family: Mont;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
      }

      &__sub-heading {
        color: var(--Navy-Navy---100, #142669);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Mont;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
    }

    h2 {
      font-weight: 700;
      margin-bottom: 2rem;
      font-size: 1.5rem;
      color: #333333;

      @media (min-width: 768px) {
        font-size: 1.75rem;
      }

      @media (min-width: 992px) {
        font-size: 2rem;
      }
    }

    .heading-resource-center {
      max-width: 1400px;
      padding: 0 2rem;
    }

    .content-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 36px;
      width: auto;

      .heading-title {
        height: 36px;

        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 114%;

        display: flex;
        align-items: center;
        letter-spacing: 0.005em;

        color: #142669;

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .resource-dropdown {
        .dropdown-menu {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          inset: 20px 0 0 0 !important;
          margin-left: -140px !important;

          width: 180px;
          height: 130px;

          background: #ffffff;

          box-shadow: 0px 0px 30px rgba(104, 104, 104, 0.2);
          border: 1px solid transparent;
          border-radius: 12px;

          .a {
            height: 26px;

            font-family: 'Mont';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 160%;

            font-feature-settings: 'liga' off;
          }

          .dropdown-item {
            padding: 6.5px 16px;
            color: #142669;

            &:hover {
              background: #ebebeb;
            }
          }

          .dropdown-item,
          .active {
            background-color: transparent;
          }
        }
      }
    }

    .resource-card {
      width: 320px;
      height: auto;
      background: white;
      padding: 0;
      position: relative;
      box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
      border-radius: 12px;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 100%;
        max-width: 340px;
      }

      .kit-image-wrapper {
        width: 100%;
        height: 170px;

        @media (max-width: 768px) {
          height: 190px;
        }
      }

      .kit-image {
        width: 100%;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
      }

      .locked__badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 53px;
        height: 37px;

        position: absolute;
        top: 133px;
        padding: 6px 14px;
        z-index: 3;

        color: #fff;
        background: #cc7191;
        left: 0;
        font-size: 0.9rem;
        font-weight: 700;
        bottom: 0;
        border-radius: 0 1em 0 0;
        text-transform: uppercase;
      }

      .play__badge {
        position: absolute;
        top: 45px;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 3;
      }

      .card-text-area {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 10px 14px;

        .text-card-title {
          font-family: 'Mont';
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          font-size: 24px;

          letter-spacing: 0.005em;

          color: #142669;

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .text-author {
          margin-bottom: 1.75rem;
          font-family: 'Mont';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 160%;

          display: flex;
          align-items: center;

          color: #142669;

          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .card-read-more {
          height: 20px;

          position: absolute;
          bottom: 15px;
          right: 15px;

          align-self: flex-end;
          border-bottom: 2px solid #cc7191;
          cursor: pointer;

          .text-read-more {
            font-family: 'Mont';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 160%;

            display: flex;
            align-items: center;
            text-align: center;
            font-feature-settings: 'liga' off;

            color: #cc7191;
          }
        }
      }

      &__locked {
        text-decoration: none;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        z-index: 3;
        width: 100%;
        height: 100%; /* Fallback for browsers that don't support the below properties */
        height: -webkit-fill-available; /* Chrome, Safari, newer versions of Opera */
        height: -moz-available; /* Firefox */
        background: rgba(0, 0, 0, 0.7);

        img {
          display: flex;
        }

        p {
          display: block;
          margin: 0;
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-feature-settings: 'liga' off;
          font-family: Mont;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }

        &__text {
          display: flex;
          padding: 4px 0px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.8);
        }
      }
    }

    .load-more {
      display: flex;
      justify-content: center;

      .no-more-articles {
        color: black;
      }
    }
  }
}
