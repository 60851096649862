.editUserProfile__modal {
  .editUserProfile__modalClose {
    width: 100%;
    position: absolute;
    right: 1rem;
    max-width: 1.5rem;
    top: 1rem;
    cursor: pointer;
  }

  .modal-body {
    padding: 2em;
  }

  .editUserProfile__form {
    width: 100%;
  }

  label {
    font-weight: 700;
    margin-bottom: 2rem;
    font-family: 'Mont', Arial, Helvetica, sans-serif;
  }

  textarea {
    font-family: 'Mont', Arial, Helvetica, sans-serif;
    padding: 1em 0.75em;
    background: transparent;
    border-radius: 1.25em;
    font-size: 0.9rem;

    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #bdbdbd;

    &::placeholder {
      color: #bdbdbd;
    }

    &[type='number'] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance: textfield;
    }
  }

  textarea {
    height: 9rem;
    resize: none;
  }
}
