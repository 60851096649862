.deals {
  .filters {
    @media (max-width: 1200px) {
      display: none;
    }

    position: sticky;
    top: 0;
    margin-top: -40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 2rem;
    padding: 20px 0;
    border-radius: 0 0 40px 40px;

    background: #2a6359;
    z-index: 10;

    .filter-card {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 12px 7px;
      gap: 10px;
      z-index: 10;

      width: 145px;
      height: 65px;

      background: #2a6359;
      color: white;
      border: 2px solid #fff;
      box-shadow: 0px 0px 25.1111px rgba(20, 38, 105, 0.1);
      border-radius: 13px;

      cursor: pointer;
      &.active {
        background: #fff;
        color: #2a6359;
      }
    }
  }

  .deals_header {
    font-weight: 600;
    color: #142669;
    font-size: 1.8rem;
    margin-top: 10px;
  }
}

.deals-banner {
  width: 100%;
  display: flex;
  margin: 20px 0;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #e8f3f8;

  h6 {
    margin: 10px;
    color: #142669;
    text-align: center;
    font-feature-settings: 'liga' off;

    font-family: 'Mont';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  p {
    margin: 0;
    color: #142669;
    text-align: center;
    font-feature-settings: 'liga' off;

    font-family: 'Mont';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }
}
