.breeed_directory_container {
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 auto 100px auto;
  padding: 20px;

  .directory_heading {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    max-width: 1600px;
    padding: 30px;
    height: 268px;

    background: #142669;

    box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
    border-radius: 18px;

    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
      padding: 10px;
      gap: 10px;
      align-items: center;
    }

    h1 {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 114%;

      letter-spacing: 0.005em;

      color: #ffffff;

      @media (max-width: 1200px) {
        font-size: 30px;
      }

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      margin: 0;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.005em;
      color: #ffffff;

      @media (max-width: 1200px) {
        font-size: 16px;
      }

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .heading_left {
      max-width: 1100px;
      width: 75%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;

      @media (max-width: 768px) {
        gap: 10px;
        width: 100%;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .heading_right {
      width: 25%;
      height: 200px;
      max-width: 200px;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 768px) {
        width: 140px;
        height: 140px;
        max-width: 140px;
        width: 100%;
      }

      .header_logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.directory_list_content {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  padding-top: 3rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 1rem;
    gap: 10px;
  }

  .directory_cards_letters_container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
    }

    .non_mobile {
      @media (max-width: 768px) {
        display: none;
      }
    }

    h5 {
      margin: 0;
      width: 100%;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #142669;

      @media (max-width: 768px) {
        &.with_border {
          border-top: 1px solid #d0d4e1;
          padding-top: 20px;
          margin-top: 10px !important;
        }
      }
    }

    .letter_box_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 12px;

      .letter_box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;

        width: 47px;
        height: 47px;

        background: #ffffff;
        border: 1px solid #d0d4e1;
        border-radius: 8px;

        cursor: pointer;

        @media (max-width: 768px) {
          width: 33px;
          height: 33px;
        }

        &.selected {
          background: #cc7191;

          color: white;
          border: 1px solid #cc7191;
          border-radius: 8px;
        }

        h5 {
          width: auto;
          margin: 0;
          text-align: center;
          font-family: 'Mont';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 120%;

          display: flex;
          align-items: center;
          letter-spacing: 0.005em;

          color: #142669;
        }
      }
    }

    .cards_container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      &.similar {
        background: #e6eaed;
        overflow-x: scroll;
        padding: 20px;
      }

      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0px;

        width: 230px;
        min-height: 300px;
        height: auto;
        background: #ffffff;

        box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
        border-radius: 12px;
        cursor: pointer;

        @media (max-width: 768px) {
          width: 100%;
          min-height: none;
          height: auto;
          padding-bottom: 10px;
        }

        &.similar {
          height: 330px;
          min-width: 246.67px;
        }

        .card_read_more {
          height: 20px;
          position: absolute;
          margin: 0 16px;
          left: 0px;
          bottom: 16px;
          align-self: flex-end;
          border-bottom: 2px solid #cc7191;
          cursor: pointer;

          .text_read_more {
            margin: 0px;
            font-family: 'Mont';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            display: flex;
            align-items: center;
            font-feature-settings: 'liga' off;
            color: #cc7191;
          }
        }

        h6 {
          text-align: center;
          margin: 0 auto;
          font-family: 'Mont';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          display: flex;
          align-items: center;
          font-feature-settings: 'liga' off;

          color: #142669;
        }

        p {
          margin: 0 16px;
          font-family: 'Mont';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 160%;

          font-feature-settings: 'liga' off;

          color: #142669;
        }

        .image_container {
          position: relative;
          height: 240px;
          width: 100%;
          border-radius: 12px 12px 0px 0px;

          @media (max-width: 768px) {
            width: 100%;
            height: 2500px;
            max-height: 250px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px 12px 0px 0px;

            @media (max-width: 768px) {
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.directory_filters_mobile_display {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;

    h5 {
      margin: 0;
      width: 100%;
      font-family: 'Mont';
      font-style: normal;
      font-weight: 700;
      font-size: 16px !important;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #142669;
    }
  }
}
