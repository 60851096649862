.resource__filtersContainer {
  position: sticky;
  top: 0;
  margin-left: 0;
  margin-top: 40px;
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 300px;
  max-width: 300px;
  height: 100vh;

  overflow-y: auto;

  background: #ffffff;

  box-shadow: 0px 0px 20px rgba(20, 38, 105, 0.1);
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;

  &.subfilter {
    overflow-y: hidden;
    box-shadow: none;

    padding: 0px 0px 0px 34px;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;

    flex: none;
    order: 1;
    flex-grow: 0;

    .checkbox {
      font-size: 12px;
      line-height: 160%;
    }
  }

  .single-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
